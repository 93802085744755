import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { ErrorCode, ToastType } from '../../enum/feedback';
import { FileLabelEnum } from '../../enum/file-label';
import { FeedBack } from '../../models/feedback';
import { OrderFile } from '../../models/order';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { ordersActions } from '../../store/orders/orders.reducer';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../services/files-api.services';
import {
  usePredictPatientFileLabelMutation,
  useUploadPatientFileToOrderMutation,
  useUploadToStorageMutation
} from '../../services/orders-api.services';
import { getMessageError } from '../../utils/utils';
import FileSaver from 'file-saver';

const allowedThumbprintExtensions = ['stl', 'ply', 'obj'];
const allowedImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'heic', 'heif', 'mtl'];
const allowedVideoExtensions = [
  'mov',
  'mp4',
  'avi',
  'wmf',
  'flv',
  'webm',
  'mpg',
  'mpa',
  'vob',
  'mpeg',
  'h264',
  'h265'
];

type LazyGetOneDownloadableFileQueryTuple = ReturnType<typeof useLazyGetOneDownloadableFileQuery>;
type LazyGetOneDownloadableFileQueryTrigger = LazyGetOneDownloadableFileQueryTuple[0];
type DownloadFileFromStorageMutationTuple = ReturnType<typeof useDownloadFileFromStorageMutation>;
type DownloadFileFromStorageMutationTrigger = DownloadFileFromStorageMutationTuple[0];
type PredictPatientFileLabelMutationTuple = ReturnType<typeof usePredictPatientFileLabelMutation>;
type PredictPatientFileLabelMutationTrigger = PredictPatientFileLabelMutationTuple[0];
type UploadPatientFileToOrderMutationTuple = ReturnType<typeof useUploadPatientFileToOrderMutation>;
type UploadPatientFileToOrderMutationTrigger = UploadPatientFileToOrderMutationTuple[0];
type UploadToStorageMutationTuple = ReturnType<typeof useUploadToStorageMutation>;
type UploadToStorageMutationTrigger = UploadToStorageMutationTuple[0];

export const checkEmptyFile = (file: OrderFile): boolean => !file?.data;

export const checkFileAlreadyUploaded = (orderFiles: OrderFile[], file: OrderFile): boolean =>
  orderFiles.some((orderFile) => areSameFiles(orderFile, file));

export const isImageExtension = (extension: string) =>
  allowedImageExtensions.includes(extension?.toLowerCase());

export const isThumbprintExtension = (extension: string) =>
  allowedThumbprintExtensions.includes(extension?.toLowerCase());

export const isVideoExtension = (extension: string) =>
  allowedVideoExtensions.includes(extension?.toLowerCase());

export const checkFileExtensionAllowed = (file: OrderFile): boolean =>
  isThumbprintExtension(file?.extension) ||
  isVideoExtension(file?.extension) ||
  isImageExtension(file?.extension);

export const mapFileToOrderFile = (
  newFileToUpload: File,
  fileLabel: FileLabelEnum = undefined
): OrderFile => {
  const extension = newFileToUpload.name.substring(newFileToUpload.name.lastIndexOf('.') + 1);
  const fileName = newFileToUpload.name.substring(0, newFileToUpload.name.lastIndexOf('.'));
  return {
    extension: extension,
    fileLabel: fileLabel,
    fileName: fileName,
    mimeType: 'application/octet-stream',
    data: newFileToUpload
  };
};

export const getTextureFile = async (
  file3D: OrderFile,
  orderFiles: OrderFile[]
): Promise<OrderFile> => {
  if (file3D) {
    // Header attribute value to describe the texture file name in 3D file
    const textureFileHeader = 'comment TextureFile ';

    const header = await getFile3dHeader(file3D);
    if (header.includes(textureFileHeader)) {
      let textureFileName = header.substring(
        header.indexOf(textureFileHeader) + textureFileHeader.length
      );
      // Get texture file name in the file3D header
      textureFileName = textureFileName.substring(0, textureFileName.indexOf('\n'));
      // Search if the texture file has been uploaded
      const textureFile = orderFiles.filter(
        (file) =>
          replaceNonASCII(file.fileName + '.' + file.extension) === replaceNonASCII(textureFileName)
      );
      if (textureFile?.length) {
        return textureFile[0];
      }
    }
  }
};

const replaceNonASCII = (str: string) => {
  const asciiRange = `${String.fromCharCode(0)}-${String.fromCharCode(127)}`;
  const nonASCIIRegex = new RegExp(`[^${asciiRange}]`, 'g');
  return str.replace(nonASCIIRegex, '?');
};

const getFile3dHeader = async (file3D: OrderFile): Promise<string> => {
  const response = await fetch(URL.createObjectURL(file3D.data));
  const data: string = await response.text();
  // Return only the header of the file
  return data.substring(0, data.indexOf('end_header'));
};

export const downloadFile = (file: OrderFile): void => {
  FileSaver.saveAs(file.data, `${file.fileName}.${file.extension}`);
};

export const downloadFiles = (url: string): void => {
  FileSaver.saveAs(url, 'download.zip');
};

export const checkFile = (
  file: OrderFile,
  files: OrderFile[],
  dispatch: Dispatch<PayloadAction<FeedBack>>
): boolean => {
  if (checkEmptyFile(file)) {
    dispatch(
      feedbackActions.setToast({
        message: i18next.t(ErrorCode.ORDERS_FILE_EMPTY, { ns: 'error' }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  if (checkFileAlreadyUploaded(files, file)) {
    dispatch(
      feedbackActions.setToast({
        message: i18next.t(ErrorCode.ORDERS_FILE_ALREADY_EXISTS, { ns: 'error' }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  if (!checkFileExtensionAllowed(file)) {
    dispatch(
      feedbackActions.setToast({
        message: i18next.t(ErrorCode.ORDERS_FILE_NOT_ALLOWED, {
          ns: 'error',
          extension: file.extension
        }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  return true;
};

/**
 * Display fin in viewer using redux for creation order
 * @param {OrderFile} orderFiles
 * @param {OrderFile} fileToDisplay
 * @param {Dispatch<PayloadAction<OrderFile>>} dispatch
 */
export const displayFileInViewer = async (
  orderFiles: OrderFile[],
  fileToDisplay: OrderFile,
  dispatch: Dispatch<PayloadAction<OrderFile> | PayloadAction<OrderFile[]> | PayloadAction<boolean>>
): Promise<void> => {
  resetFiles(dispatch);
  if (orderFiles?.length && fileToDisplay?.data) {
    const alreadyLoadedFile = orderFiles.find(
      (thisFile) => areSameFiles(fileToDisplay, thisFile) && thisFile.data
    );

    if (alreadyLoadedFile) {
      if (isThumbprintExtension(fileToDisplay.extension)) {
        await set3DFile(dispatch, alreadyLoadedFile, orderFiles);
      }

      if (isImageExtension(fileToDisplay.extension)) {
        dispatch(ordersActions.setFileImageToDisplay(alreadyLoadedFile));
      }
    }
  }
};

/**
 * Reset all file redux states.
 * @param dispatch
 */
const resetFiles = (dispatch: Dispatch<PayloadAction<OrderFile | OrderFile[] | boolean>>) => {
  dispatch(ordersActions.setFile3dToDisplay(undefined));
  dispatch(ordersActions.setFileTextureToDisplay(undefined));
  dispatch(ordersActions.setFileImageToDisplay(undefined));
};

/**
 * Set 3D and texture files redux states.
 * @param dispatch
 * @param fileToSet
 * @param orderFiles
 */
const set3DFile = async (
  dispatch: Dispatch<PayloadAction<OrderFile | OrderFile[] | boolean>>,
  fileToSet: OrderFile,
  orderFiles: OrderFile[]
): Promise<void> => {
  // Display file
  dispatch(ordersActions.setFile3dToDisplay(fileToSet));
  // Then check if there is an associated texture to this file
  // (We need to wait because we need the actual file for checking texture)
  const fileTexture = await getTextureFile(fileToSet, orderFiles);
  dispatch(ordersActions.setFileTextureToDisplay(fileTexture));
};

const setLoadedFile = (
  dispatch: (value: PayloadAction<OrderFile[] | boolean>) => void,
  updatedOrderFiles: OrderFile[],
  orderFile: OrderFile,
  fileLabel?: FileLabelEnum,
  blobFile?: BlobPart
): OrderFile[] => {
  if (orderFile && updatedOrderFiles?.length) {
    updatedOrderFiles = updatedOrderFiles.map((file) =>
      areSameFiles(file, orderFile)
        ? {
            ...file,
            data: blobFile ? new File([blobFile], orderFile.fileName) : file.data,
            fileLabel: fileLabel ?? file.fileLabel,
            isLoading: undefined
          }
        : file
    );
    dispatch(ordersActions.setFiles(updatedOrderFiles));
    dispatch(ordersActions.setLoadingFiles(false));
    return updatedOrderFiles;
  }
};

const setLoadingFile = (
  dispatch: (value: PayloadAction<OrderFile[] | boolean>) => void,
  updatedOrderFiles: OrderFile[],
  orderFile: OrderFile
): OrderFile[] => {
  updatedOrderFiles = updatedOrderFiles.map((file) =>
    areSameFiles(file, orderFile)
      ? {
          ...file,
          isLoading: true
        }
      : file
  );
  dispatch(ordersActions.setFiles(updatedOrderFiles));
  dispatch(ordersActions.setLoadingFiles(true));
  return updatedOrderFiles;
};

/**
 * Loads order files data.
 *
 * @param dispatch - The dispatch function for sending actions.
 * @param getOneDownloadableFile - The function for getting a downloadable file.
 * @param downloadFromStorage - The function for downloading a file from storage.
 * @param orderNumber - The order number.
 * @param orderFiles - The order files.
 * @returns void
 */
export const loadOrderFilesData = (
  dispatch: Dispatch<PayloadAction<OrderFile[] | FeedBack | boolean>>,
  getOneDownloadableFile: LazyGetOneDownloadableFileQueryTrigger,
  downloadFromStorage: DownloadFileFromStorageMutationTrigger,
  orderNumber: string,
  orderFiles: OrderFile[]
): void => {
  let loadedFiles = Object.assign([], orderFiles);
  const filesToDownload = loadedFiles?.filter((file) => !file.data && file.id);
  Promise.all(
    filesToDownload.map((fileToDownload) => {
      loadedFiles = setLoadingFile(dispatch, loadedFiles, fileToDownload);

      getOneDownloadableFile({ orderNumber, fileId: fileToDownload.id })
        .unwrap()
        .then((downloadableFile) => {
          if (downloadableFile?.link) {
            downloadFromStorage({ url: downloadableFile.link })
              .unwrap()
              .then((blobFile) => {
                loadedFiles = setLoadedFile(
                  dispatch,
                  loadedFiles,
                  downloadableFile,
                  downloadableFile.fileLabel,
                  blobFile
                );
              });
          }
        })
        .catch((error) => {
          setLoadedFile(dispatch, orderFiles, fileToDownload);
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    })
  );
};

/**
 * Removes a file from the orderFiles array and updates the Redux store.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {OrderFile[]} orderFiles - The array of order files.
 * @param {OrderFile} fileToDelete - The file to be deleted.
 * @param {OrderFile} file3dToDisplay - The current 3D file being displayed.
 * @returns {void}
 */
export const removeFile = (
  dispatch: Dispatch<PayloadAction<OrderFile[]>>,
  orderFiles: OrderFile[],
  fileToDelete: OrderFile,
  file3dToDisplay: OrderFile
): void => {
  const orderFilesCopy: OrderFile[] = Object.assign([], orderFiles);
  const fileIndex = orderFilesCopy.findIndex((file) => areSameFiles(file, fileToDelete));
  if (fileIndex > -1) {
    orderFilesCopy.splice(fileIndex, 1);
    dispatch(ordersActions.setFiles(orderFilesCopy));

    const isDeleteFileDisplayed = areSameFiles(file3dToDisplay, fileToDelete);
    if (!orderFilesCopy.length) {
      resetFiles(dispatch);
    }
    if (isDeleteFileDisplayed) {
      // the current display 3d file is deleted, so display another one
      displayFileInViewer(orderFilesCopy, orderFilesCopy[orderFilesCopy.length - 1], dispatch);
    }
  }
};

/**
 * Adds files to the order and performs various operations on them.
 *
 * @param {Dispatch<PayloadAction<OrderFile[] | OrderFile | FeedBack | boolean>>} dispatch - The dispatch function from the React Redux store.
 * @param {PredictPatientFileLabelMutationTrigger} predictLabel - The trigger function for predicting the label of the patient file.
 * @param {OrderFile[]} orderFiles - The array of existing order files.
 * @param {File[]} newFilesToUpload - The array of new files to be uploaded.
 * @returns {Promise<void>} - A promise that resolves once all operations are completed.
 */
export const addFile = async (
  dispatch: Dispatch<PayloadAction<OrderFile[] | OrderFile | FeedBack | boolean>>,
  predictLabel: PredictPatientFileLabelMutationTrigger,
  orderFiles: OrderFile[],
  newFilesToUpload: File[]
): Promise<OrderFile[]> => {
  let newLoadedFilesList = [...orderFiles];
  for (const newFileToUpload of newFilesToUpload) {
    const orderFile = mapFileToOrderFile(newFileToUpload);
    if (!checkFile(orderFile, orderFiles, dispatch)) {
      return;
    }
    newLoadedFilesList = [...newLoadedFilesList, ...[orderFile]];
    // Display new file with a loader
    newLoadedFilesList = setLoadingFile(dispatch, newLoadedFilesList, orderFile);

    const prediction = await predictLabel({
      fileName: `${orderFile.fileName}.${orderFile.extension}`
    });
    const fileLabel = 'data' in prediction ? prediction.data : FileLabelEnum.UNKNOWN;

    // Display new file without loader and with label prediction
    newLoadedFilesList = setLoadedFile(dispatch, newLoadedFilesList, orderFile, fileLabel);
  }
  // Display one file uploaded in viewer
  await displayFileInViewer(
    newLoadedFilesList,
    newLoadedFilesList[newLoadedFilesList.length - 1],
    dispatch
  );
  return newLoadedFilesList;
};

export const uploadToStorages = async (
  dispatch: Dispatch<PayloadAction<OrderFile[] | OrderFile | FeedBack | number | boolean>>,
  uploadToStorage: UploadToStorageMutationTrigger,
  newOrderFiles: OrderFile[],
  newFilesToUpload: OrderFile[],
  setUploadedFilesNumber?: Dispatch<SetStateAction<number>>
): Promise<void> => {
  for (const newFileToUpload of newFilesToUpload) {
    const uploadToGcs = uploadToStorage({
      url: newFileToUpload.uploadUrl,
      file: newFileToUpload.data
    });
    const uploadToS3 = uploadToStorage({
      url: newFileToUpload.uploadUrlS3,
      file: newFileToUpload.data
    });
    Promise.all([uploadToGcs, uploadToS3])
      .then(() => {
        newOrderFiles = newOrderFiles.map((file) =>
          areSameFiles(file, newFileToUpload)
            ? {
                ...file,
                id: newFileToUpload.id,
                isLoading: undefined
              }
            : file
        );
        dispatch(ordersActions.setFiles(newOrderFiles));
        if (setUploadedFilesNumber) {
          setUploadedFilesNumber((prev) => prev + 1);
        }
      })
      .catch((error) => {
        newOrderFiles = setLoadedFile(dispatch, newOrderFiles, newFileToUpload);
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      })
      .finally(() => dispatch(ordersActions.setLoadingFiles(false)));
  }
};

export const uploadFilesToBackEnd = async (
  dispatch: Dispatch<PayloadAction<OrderFile[] | OrderFile | FeedBack | number | boolean>>,
  uploadToOrder: UploadPatientFileToOrderMutationTrigger,
  uploadToStorage: UploadToStorageMutationTrigger,
  newOrderFiles: Array<OrderFile>,
  orderNumber: string,
  setUploadedFilesNumber?: Dispatch<SetStateAction<number>>
): Promise<void> => {
  const newFileList: OrderFile[] = newOrderFiles?.length
    ? newOrderFiles.filter((file) => !file.id)
    : [];
  for (const newFileToUpload of newFileList) {
    dispatch(ordersActions.setLoadingFiles(true));
    await uploadToOrder({
      orderNumber: orderNumber,
      file: { ...newFileToUpload, isLoading: undefined, data: undefined }
    })
      .unwrap()
      .then((result) => {
        uploadToStorages(
          dispatch,
          uploadToStorage,
          newOrderFiles,
          [
            {
              ...newFileToUpload,
              id: result.id,
              uploadUrl: result.uploadUrl,
              uploadUrlS3: result.uploadUrlS3
            }
          ],
          setUploadedFilesNumber
        );
      })
      .catch((error) => {
        const fileIndex = newFileList.findIndex((file) => areSameFiles(file, newFileToUpload));
        if (fileIndex > -1) {
          newFileList.splice(fileIndex, 1);
          dispatch(ordersActions.setFiles(Object.assign([], newFileList)));
        }
        newOrderFiles = setLoadedFile(dispatch, newOrderFiles, newFileToUpload);
        dispatch(ordersActions.setLoadingFiles(false));
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  }
};

export const areSameFiles = (file: OrderFile, otherFile: OrderFile): boolean =>
  file?.fileName === otherFile?.fileName && file?.extension === otherFile?.extension;
