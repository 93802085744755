import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './streaming-page.module.scss';
import { useGetOneManufacturingOrderQuery } from '../../../services/manufacturing-orders-api.services';
import { useTranslation } from 'react-i18next';
import { Text } from '@anatoscope/circlestorybook';
import { WorkflowStepEnum } from '../../../enum/workflow-step';
import { VncViewer } from '@anatoscope/react-vncviewer';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { StreamingParams } from '../../../enum/streaming';

const StreamingPage = () => {
  const { t } = useTranslation();
  const [streamingParams, setStreamingParams] = useState<StreamingParams | undefined>(undefined);
  const vncViewerRef = useRef<React.ElementRef<typeof VncViewer>>(null);
  const [displayClosingPage, setDisplayClosingPage] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(null);

  const { orderNumber } = useParams();
  const [skipValue, setSkipValue] = useState<boolean>(false);

  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderNumber, {
    pollingInterval: 10000,
    skip: skipValue
  });

  useEffect(() => {
    let action = null;
    switch (manufacturingOrder?.currentStep) {
      case WorkflowStepEnum.MODELING_PREPARE: {
        action = 'prepare';
        break;
      }

      case WorkflowStepEnum.MODELING_DESIGN: {
        action = 'design';
        break;
      }

      case WorkflowStepEnum.DELIVERY:
      case WorkflowStepEnum.CONTROL:
      case WorkflowStepEnum.MANUFACTURING: {
        action = 'view';
        break;
      }
    }

    if (action) {
      document.title = document.title = `${t('streamer.' + action, { ns: 'circleCadStreamer' })} ${
        manufacturingOrder.orderNumber
      }`;
    }
  }, [manufacturingOrder]);

  if (manufacturingOrder?.streamingParameters) {
    setSkipValue(true);
    setStreamingParams(JSON.parse(manufacturingOrder?.streamingParameters));
  }

  const onConnectionLost = () => {
    setDisplayClosingPage(true);
    setTimeLeft(5);
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      window.close();
    }

    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <>
      <div className={styles['streaming-page__wait-screen']}>
        <div className={styles['streaming-page__wait-screen--logo-wrapper']}>
          <img
            alt="cirle logo"
            className={styles['streaming-page__wait-screen--logo']}
            src="images/logo/logo-circle-label-white.svg"
          />
          <img alt="" className={styles['streaming-page__spacer']} src="images/separation.png" />
          {manufacturingOrder?.currentStep === WorkflowStepEnum.MODELING_PREPARE && (
            <img
              alt="digital twin"
              className={styles['streaming-page__spacer']}
              src="images/digital-twin.svg"
            />
          )}

          {manufacturingOrder?.currentStep === WorkflowStepEnum.MODELING_DESIGN && (
            <img
              alt="intuitive design"
              className={styles['streaming-page__spacer']}
              src="images/intuitive-design.svg"
            />
          )}
          {(manufacturingOrder?.currentStep === WorkflowStepEnum.DELIVERY ||
            manufacturingOrder?.currentStep === WorkflowStepEnum.CONTROL ||
            manufacturingOrder?.currentStep === WorkflowStepEnum.MANUFACTURING) && (
            <img alt="view" className={styles['streaming-page__spacer']} src="images/view.svg" />
          )}
        </div>
        <article className={styles['streaming-page__text-container']}>
          <div className={styles['streaming-page__text-aligner']}>
            <span className={styles['streaming-page__text-wrapper']}>
              {manufacturingOrder?.orderNumber}
            </span>
            <div>
              <Text
                color={ColorPropsEnum.WHITE}
                label={
                  displayClosingPage
                    ? t('streamer.closing', { second: timeLeft, ns: 'circleCadStreamer' })
                    : t('streamer.loading', { ns: 'circleCadStreamer' })
                }
              />
            </div>
          </div>
        </article>
      </div>
      {streamingParams && !displayClosingPage && (
        <div className={styles['streaming-page__vnc-container']}>
          <VncViewer
            autoConnect={true}
            ref={vncViewerRef}
            className={styles['streaming-page__vnc-container__viewer']}
            url={`wss:${streamingParams?.host}:${streamingParams?.port}/${streamingParams?.path}`}
            password={streamingParams?.password}
            showMenu={true}
            scaleViewport={true}
            onConnectionLost={onConnectionLost}
          />
        </div>
      )}
    </>
  );
};

export default StreamingPage;
