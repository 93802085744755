import { api } from './api';
import { OrderFile } from '../models/order';

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneDownloadableFile: builder.query<OrderFile, { orderNumber: string; fileId: number }>({
      query: (arg) => {
        const { orderNumber, fileId } = arg;
        return {
          url: `/orders/${orderNumber}/patient-files/${fileId}`,
          method: 'GET'
        };
      }
    }),
    downloadFileFromStorage: builder.mutation<BlobPart, { url: string }>({
      query: (arg) => {
        const { url } = arg;
        const encodedUrl = `${url.substring(0, url.lastIndexOf('/') + 1)}${encodeURIComponent(
          url.substring(url.lastIndexOf('/') + 1)
        )}`;
        return {
          url: encodedUrl,
          method: 'GET',
          responseHandler: async (response) => await response.blob()
        };
      }
    })
  })
});

export const { useLazyGetOneDownloadableFileQuery, useDownloadFileFromStorageMutation } = filesApi;
