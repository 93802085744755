import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Chips, TeethMap } from '@anatoscope/circlestorybook';
import styles from './print-order.module.scss';
import { Order, OrderItem, OrderItemComponent } from '../../models/order';
import { DentalNotation } from '../../enum/user';
import { SortedItemsForPrint } from './print-order';
import {
  computeLineColors,
  getFormattedLocalizedMomentDate
} from '../order-manager/teeth-map/utils';
import { UserInfo } from '../../models/user';
import PrintProductList from './PrintProductList';
import { OrderItemCard } from '../order-item-card/OrderItemCard';
import { CardDisplayMode } from '../../enum/product.enum';
import { MaterialEnum } from '../../enum/component';
import { getMaterialColor } from '../../utils/order.utils';

interface PrintOrderProps {
  order: Order;
  sortedItems: SortedItemsForPrint;
  connectedUser?: UserInfo;
  patientMouth?: { [key: string]: Array<number> | Array<Array<number>> };
  teethShades?: { [key: string]: Array<number> };
  existingTeethmap?: ReactElement;
}

const ComponentToPrint = React.forwardRef<HTMLDivElement, PrintOrderProps>((props, ref) => {
  const { t } = useTranslation(['print']);
  const { order, sortedItems, connectedUser, patientMouth, teethShades, existingTeethmap } = props;
  const orderNumberShort = order?.orderNumber?.slice(-3);

  const materials = order?.items
    ?.map((item: OrderItem) => {
      return item?.itemComponents?.map((itemComponent: OrderItemComponent) => {
        return itemComponent.material?.code;
      });
    })
    .flat()
    .filter((material) => material !== undefined);
  const uniqueMaterials = [...new Set(materials)];

  return (
    <div ref={ref}>
      <div className={styles['print-order']}>
        <div className={styles['print-order__left']}>
          <div className={styles['print-order__left__top']}>
            <div className={styles['print-order__header']}>
              <div className={styles['print-order__header__dentist']}>
                <div>{order?.dentistName}</div>
              </div>
              <div className={styles['print-order__header__orderNumber']}>{orderNumberShort}</div>
              <div className={styles['print-order__header__dates']}>
                {getFormattedLocalizedMomentDate('dayMonth', order?.limitShippingDate)}
              </div>
            </div>
            <div className={styles['print-order__teethMapSection']}>
              <div className={styles['print-order__teethMapSection__products']}>
                <div className={styles['print-order__teethMapSection__products__section']}>
                  {order && sortedItems && <PrintProductList sortedItems={sortedItems} />}
                </div>
              </div>
              <div className={styles['print-order__teethMapSection__teethMap']}>
                {order?.tags && (
                  <div className={styles['print-order__tagsList']}>
                    <div className={styles['print-order__tagsList__title']}>{t('TagsList')}</div>
                    <div className={styles['print-order__tagsList__tag']}>
                      {order.tags.join(', ')}
                    </div>
                  </div>
                )}
                <div className={styles['print-order__productCountMaterial']}>
                  {order?.items && (
                    <div>{t('productCount.counter', { count: order?.items.length })}</div>
                  )}
                  {uniqueMaterials.length > 0 && (
                    <>
                      <div className={styles['print-order__colon']}>{':'}</div>
                      <div className={styles['print-order__productCountMaterial__materialsList']}>
                        {uniqueMaterials.map((material: MaterialEnum) => {
                          return (
                            <Chips
                              key={material}
                              firstLabel={t(`material.${material}`, { ns: 'component' })}
                              color={getMaterialColor(material)}
                              size={'s'}
                              className={
                                styles['print-order__productCountMaterial__materialsList__chip']
                              }
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                {!existingTeethmap && (
                  <TeethMap
                    patientMouth={patientMouth}
                    notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
                    teethShades={teethShades}
                    lineAndNumberColors={computeLineColors(order?.items)}
                    displayShadows={false}
                    hiddenBubbles={true}
                  />
                )}
                {existingTeethmap}
                <div className={styles['print-order__instructionSection']}>
                  <span className={styles['print-order__instructionSection__title']}>
                    {t('instructions')}
                  </span>
                  {order?.instructions ? <>{order.instructions}</> : '-'}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['print-order__fullOrderNumber']}>{order?.orderNumber}</div>
        </div>
        <div className={styles['print-order__right']}>
          <div className={styles['print-order__productsDetailsSection']}>
            {order?.items?.map((item: OrderItem) => {
              return (
                <OrderItemCard key={item.id} item={item} displayMode={CardDisplayMode.PRINT} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
ComponentToPrint.displayName = 'ComponentToPrint';

const PrintOrder = React.forwardRef((props: PrintOrderProps, ref) => {
  return <ComponentToPrint ref={ref as React.Ref<HTMLDivElement>} {...props} />;
});
PrintOrder.displayName = 'PrintOrder';

export default PrintOrder;
