import React, { useEffect, useState } from 'react';
import {
  Chips,
  Divider,
  IconButton,
  Link,
  SideBarModal,
  Skeleton,
  Text,
  Toggle,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import styles from '../../order-detail.module.scss';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import { getFormattedLocalizedMomentDate } from '../../../../../features/order-manager/teeth-map/utils';
import { useAppDispatch } from '../../../../../store/hooks';
import AssignOrderForm from '../../../dashboard-page/assign-order-form/AssignOrderForm';
import { usePatchOrderMutation } from '../../../../../services/orders-api.services';
import {
  useLazyGetOneManufacturingOrderQuery,
  usePatchManufacturingOrderMutation
} from '../../../../../services/manufacturing-orders-api.services';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../enum/feedback';
import OrderDetailFile from './order-detail-file/OrderDetailFile';
import TeethMapDisplay from './teethmap-display/TeethMapDisplay';
import OrderDetailTimeline from './order-detail-timeline/OrderDetailTimeline';
import ChangeStepForm from '../../../dashboard-page/change-step-form/ChangeStepForm';
import { OrderItemCard } from '../../../../../features/order-item-card/OrderItemCard';
import { capitalizeFirstLetter, getMessageError } from '../../../../../utils/utils';
import { isManufactured, isSubmitted, isValidated } from '../../../../../utils/order.utils';
import { Order } from '../../../../../models/order';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';

enum FormToDisplayEnum {
  NONE = 'NONE',
  ASSIGN = 'ASSIGN',
  CHANGE_STEP = 'CHANGE_STEP'
}

const DetailTab = ({
  order,
  skipManufacturingOrderLoad
}: {
  order: Order;
  skipManufacturingOrderLoad: boolean;
}) => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const [getOneManufacturingOrder] = useLazyGetOneManufacturingOrderQuery();
  const [manufacturingOrder, setManufacturingOrder] = useState<ManufacturingOrder | undefined>();
  const isOrderSubmitted = isSubmitted(order?.currentStep);
  const isOrderValidated = isValidated(order?.currentStep);
  const isOrderManufactured = isManufactured(order?.currentStep);
  const [isSideBarOpened, setIsSideBarOpened] = useState(false);
  const [formToDisplay, setFormToDisplay] = useState<FormToDisplayEnum>(FormToDisplayEnum.NONE);
  const [unassignOrder, { data: patchedOrderAfterAssign, isSuccess: isSuccessUnassignOrder }] =
    usePatchOrderMutation();
  const [unassignManufacturingOrder, { isSuccess: isSuccessUnassignManufacturingOrder }] =
    usePatchManufacturingOrderMutation();
  const [toggleManufactureOrder, { isSuccess: isSuccessToggleManufactureOrder }] =
    usePatchOrderMutation();
  const [
    toggleManufactureManufacturingOrder,
    { isSuccess: isSuccessToggleManufactureManufacturingOrder }
  ] = usePatchManufacturingOrderMutation();

  const handleToggleToManufacture = () => {
    toggleManufactureOrder({
      orderNumber: order?.orderNumber,
      toManufacture: !order.toManufacture
    });
  };

  const handleUnassignOrderClick = async () => {
    await unassignOrder({
      orderNumber: order?.orderNumber,
      assigneeEmail: null,
      assigneeName: null
    });
  };

  const handleSidebarCloseEvent = () => {
    setIsSideBarOpened(false);
    setFormToDisplay(FormToDisplayEnum.NONE);
  };

  useEffect(() => {
    // Property skipManufacturingOrderLoad is temporary and will be removed once pubsub is set up
    if (isOrderValidated && !skipManufacturingOrderLoad) {
      getOneManufacturingOrder(order.orderNumber)
        .unwrap()
        .then((result: ManufacturingOrder) => {
          setManufacturingOrder(result);
        })
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  }, [isOrderValidated]);

  useEffect(() => {
    if (isSuccessUnassignOrder) {
      if (isOrderValidated) {
        unassignManufacturingOrder({
          orderNumber: patchedOrderAfterAssign.orderNumber,
          assigneeEmail: null,
          assigneeName: null
        });
      } else {
        dispatch(
          feedbackActions.setToast({
            message: t('toast.unassign', { ns: 'dashboard' }),
            type: ToastType.SUCCESS
          })
        );
      }
    }
  }, [isSuccessUnassignOrder]);

  useEffect(() => {
    if (isSuccessUnassignManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.unassign', { ns: 'dashboard' }),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSuccessUnassignManufacturingOrder]);

  useEffect(() => {
    if (isSuccessToggleManufactureOrder) {
      // Only try to update manufacturing order when we know it exists in db
      // Which means that the order was previously validated by lab
      if (isOrderValidated) {
        toggleManufactureManufacturingOrder({
          orderNumber: order?.orderNumber,
          toManufacture: false
        });
      } else {
        dispatch(
          feedbackActions.setToast({
            message: t(
              order?.toManufacture ? 'toast.setNotToBeManufactured' : 'toast.setToBeManufactured',
              { ns: 'dashboard' }
            ),
            type: ToastType.SUCCESS
          })
        );
      }
    }
  }, [isSuccessToggleManufactureOrder]);

  useEffect(() => {
    if (isSuccessToggleManufactureManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t(
            order?.toManufacture ? 'toast.setToBeManufactured' : 'toast.setNotToBeManufactured',
            { ns: 'dashboard' }
          ),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSuccessToggleManufactureManufacturingOrder]);

  return (
    <>
      <section
        className={styles['order-detail__content__tab__content__section']}
        data-cy="detail-tab-content">
        <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
          <div className={styles['order-detail__content__tab__content__section__tags']}>
            {order && !isOrderSubmitted && order?.rejectCauseComment && (
              <Tooltip>
                <TooltipContent
                  className={styles['order-detail__content__tab__content__section__tooltip']}
                  data-cy="rejected-order-tooltip">
                  <div
                    className={
                      styles['order-detail__content__tab__content__section__tooltip__row']
                    }>
                    <Text
                      color={ColorPropsEnum.WHITE}
                      bold={true}
                      label={t('tabs.detail.rejected.tooltip.motive', {
                        count: order?.rejectCauses.length
                      })}
                    />
                    <Text
                      color={ColorPropsEnum.WHITE}
                      label={
                        order?.rejectCauses
                          ?.map((cause) => capitalizeFirstLetter(cause))
                          ?.join(', ') || '-'
                      }
                    />
                  </div>
                  <div>
                    <Text
                      color={ColorPropsEnum.WHITE}
                      bold={true}
                      label={t('tabs.detail.rejected.tooltip.comment')}
                    />
                    <Text color={ColorPropsEnum.WHITE} label={order?.rejectCauseComment} />
                  </div>
                </TooltipContent>
                <Chips
                  color="danger"
                  firstLabel={t('tabs.detail.rejected.title')}
                  firstIcon="fa-ban"
                  className={
                    styles['order-detail__content__tab__content__section__row--inlined__block']
                  }
                  data-cy="rejected-order-chip"
                />
              </Tooltip>
            )}
            {order?.tags?.map((tag) => (
              <Chips key={tag} firstLabel={tag} />
            ))}
          </div>
        </div>
        {order && isOrderSubmitted && !isOrderManufactured && (
          <div className={styles['order-detail__content__tab__content__section__toggle']}>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.orderToManufacture')} />
            <Toggle
              areLabelsHidden={true}
              id="toggle-to-manufacture"
              isChecked={order?.toManufacture}
              name="toggle-to-manufacture"
              onChangeCallback={handleToggleToManufacture}
            />
          </div>
        )}
        {!order && <Skeleton type="text" />}
        <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.creationDate')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={getFormattedLocalizedMomentDate('small', order?.creationDate)}
                    data-cy="creation-date-value"
                  />
                </>
              )}
              {!order && <Skeleton type="text" />}
            </div>
          </div>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.shippingDeadline')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={
                      order?.limitShippingDate
                        ? getFormattedLocalizedMomentDate('small', order?.limitShippingDate)
                        : '-'
                    }
                    data-cy="limit-shipping-date-value"
                  />
                </>
              )}
              {!order && <Skeleton type="text" />}
            </div>
          </div>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.deliveryDate')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={
                      order?.expectedDate
                        ? getFormattedLocalizedMomentDate('small', order?.expectedDate)
                        : '-'
                    }
                    data-cy="delivery-date-value"
                  />
                </>
              )}
              {order?.expectedDate && order?.isUrgent && order?.toManufacture && (
                <Tooltip>
                  <TooltipContent data-cy="late-order-tooltip">
                    {i18next.t('datagrid.status.lateOrder', { ns: 'dashboard' })}
                  </TooltipContent>
                  <span>
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className={[
                        styles['order-detail__content__tab__content__section__row--inlined__icon'],
                        styles[
                          'order-detail__content__tab__content__section__row--inlined__icon--urgent'
                        ]
                      ].join(' ')}
                      data-cy="late-order-icon"
                    />
                  </span>
                </Tooltip>
              )}

              {!order && <Skeleton type="text" />}
            </div>
          </div>
        </div>
        <div
          className={[
            styles['order-detail__content__tab__content__section__row'],
            styles['order-detail__content__tab__content__section__row--inlined']
          ].join(' ')}>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.dentist')} />
            {order && (
              <Link
                href={`/dentist/detail/${order?.dentistEmail}`}
                label={order?.dentistName || ''}
                data-cy="dentist-value"
              />
            )}
            {!order && <Skeleton type="text" />}
          </div>
        </div>
        <Divider />

        <div className={styles['order-detail__content__tab__content__section__content']}>
          <div className={styles['order-detail__content__tab__content__section__row']}>
            <div
              className={styles['order-detail__content__tab__content__section__row--inlined']}
              data-cy="order-step">
              <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.step')} />
              {isOrderValidated && (
                <IconButton
                  color={ColorPropsEnum.PRIMARY}
                  iconSize={'sm'}
                  faIconClass="fa-pen-to-square"
                  onClick={() => {
                    setIsSideBarOpened(true);
                    setFormToDisplay(FormToDisplayEnum.CHANGE_STEP);
                  }}
                  radius="full"
                  data-cy="edit-step-button"
                />
              )}
            </div>
            <div>
              {order && (
                <OrderDetailTimeline order={order} manufacturingOrder={manufacturingOrder} />
              )}
              {!order && <Skeleton type="text" width="100%" />}
            </div>
          </div>
        </div>

        <div
          className={[
            styles['order-detail__content__tab__content__section__row--inlined__block--text']
          ].join(' ')}>
          <div
            className={styles['order-detail__content__tab__content__section__row--inlined']}
            data-cy="assignee">
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.assignee')} />
            {order && !order?.assigneeEmail && (
              <IconButton
                color={ColorPropsEnum.PRIMARY}
                iconSize={'sm'}
                faIconClass="fa-pen-to-square"
                onClick={() => {
                  setFormToDisplay(FormToDisplayEnum.ASSIGN);
                  setIsSideBarOpened(true);
                }}
                radius="full"
                className={
                  styles[
                    'order-detail__content__tab__content__section__row--inlined__block--text__icon-button'
                  ]
                }
                data-cy="assignee-edit-btn--assign"
              />
            )}
            {order?.assigneeEmail && (
              <IconButton
                color={ColorPropsEnum.DANGER}
                iconSize={'sm'}
                faIconClass="fa-trash-can"
                radius="full"
                onClick={() => handleUnassignOrderClick()}
                className={
                  styles[
                    'order-detail__content__tab__content__section__row--inlined__block--text__icon-button'
                  ]
                }
                data-cy="assignee-edit-btn--remove"
              />
            )}
          </div>

          <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
            {!order && <Skeleton type="text" />}
            {order && (
              <Text
                label={order?.assigneeName || t('tabs.detail.noAssignee')}
                data-cy="assignee-value"
              />
            )}
          </div>
        </div>
        {isOrderValidated && (
          <div className={styles['order-detail__content__tab__content__section__row']}>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.circleCadVersion')} />
            {manufacturingOrder?.circleCadVersion && (
              <Chips
                color={ColorPropsEnum.WHITE}
                firstLabel={manufacturingOrder.circleCadVersion.label}
              />
            )}
            {manufacturingOrder && !manufacturingOrder?.circleCadVersion && <Text label="-" />}
            {!(order || manufacturingOrder) && <Skeleton type="text" />}
          </div>
        )}

        <div className={styles['order-detail__content__tab__content__section__row']}>
          <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.instructions')} />
          {order && <Text label={order?.instructions || '-'} data-cy="instructions-value" />}
          {!order && <Skeleton type="text" width="100%" />}
        </div>
      </section>
      <Divider />
      <section className={styles['order-detail__content__tab__content__section--large']}>
        <div className={styles['order-detail__content__tab__content__section__teethmap']}>
          <TeethMapDisplay order={order} displayProductBubbles={true} />
        </div>
        {order && (
          <div className={styles['order-detail__content__tab__content__section__files']}>
            <OrderDetailFile
              orderNumber={order?.orderNumber}
              isReadOnly={!isOrderSubmitted || isOrderValidated}
            />
          </div>
        )}
        {!order && <Skeleton type="square" width="100%" />}
      </section>
      <section
        className={[
          styles['order-detail__content__tab__content__section--large'],
          styles['order-detail__content__tab__content__section__products']
        ].join(' ')}>
        {order?.items?.map((orderItem) => (
          <OrderItemCard item={orderItem} key={orderItem.id} />
        ))}
        {!order && <Skeleton type="square" width="28%" />}
      </section>
      {formToDisplay === FormToDisplayEnum.ASSIGN && (
        <SideBarModal
          title={t('assignOrderModal.title', { orderNumber: order?.orderNumber })}
          isOpened={isSideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleSidebarCloseEvent}
          data-cy="assign-sidebar">
          <AssignOrderForm
            onSubmitAssignOrderCallback={() => {
              handleSidebarCloseEvent();
            }}
            orderNumber={order?.orderNumber}
          />
        </SideBarModal>
      )}
      {formToDisplay === FormToDisplayEnum.CHANGE_STEP && (
        <SideBarModal
          title={t('changeStepModal.title', { orderNumber: order?.orderNumber })}
          isOpened={isSideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleSidebarCloseEvent}
          data-cy="edit-step-sidebar">
          <ChangeStepForm
            onSubmitChangeStepOrderCallback={() => handleSidebarCloseEvent()}
            orderNumber={order?.orderNumber}
            currentStep={order?.currentStep}
          />
        </SideBarModal>
      )}
    </>
  );
};

export default DetailTab;
