import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  SideBarModal,
  TabItem,
  Tabs,
  Text,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import styles from './order-detail.module.scss';
import DetailTab from './tabs/detail-tab/DetailTab';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import RejectOrderForm from './reject-order-form/RejectOrderForm';
import ReactToPrint from 'react-to-print';
import PrintOrder from '../../../features/print-order/PrintOrder';
import { getSortedItemsToPrint } from '../../../features/print-order/print-order';
import TeethMapDisplay from './tabs/detail-tab/teethmap-display/TeethMapDisplay';
import {
  useGetOneOrderQuery,
  useLazyGetOneOrderQuery
} from '../../../services/orders-api.services';
import { isSubmitted, isValidated } from '../../../utils/order.utils';
import { Order } from '../../../models/order';
import { WorkflowStepEnum, WorkFlowStepPreModelingEnum } from '../../../enum/workflow-step';
import Comments from '../../../features/comments/Comments';
import { useGetAllDentistsQuery, useGetLabUsersQuery } from '../../../services/users-api.services';
import { splitOrderNumber } from '../../../features/order-manager/teeth-map/utils';
import ValidateOrderForm from './validate-order-form/ValidateOrderForm';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../../services/files-api.services';
import { useAppDispatch } from '../../../store/hooks';
import { loadOrderFilesData } from '../../../features/file-manager/file.utils';
import { ordersActions } from '../../../store/orders/orders.reducer';
import HistoryTab from './tabs/history/HistoryTab';

enum FormToDisplayEnum {
  NONE = 'NONE',
  REJECT_ORDER = 'REJECT_ORDER',
  ACCEPT_ORDER = 'ACCEPT_ORDER'
}

const OrderDetailPage = () => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const { data: orderDetail, isLoading: isOrderLoading } = useGetOneOrderQuery(orderNumber);
  const [getOneOrder, { data: orderData }] = useLazyGetOneOrderQuery();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();

  const [order, setOrder] = useState<Order>();
  const [isOrderValidated, setIsOrderValidated] = useState(undefined);
  const isOrderSubmitted = isSubmitted(order?.currentStep);
  const [isSideBarOpened, setIsSideBarOpened] = useState(false);
  const [formToDisplay, setFormToDisplay] = useState<FormToDisplayEnum>(FormToDisplayEnum.NONE);
  const [printComponentRef, setPrintComponentRef] = useState(null);
  // todo remove this after event management is implemented https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
  const [skipManufacturingOrderLoad, setSkipManufacturingOrderLoad] = useState<boolean>(false);

  // Load users lab in order to have data already loaded for Comment and history tab.
  useGetLabUsersQuery();
  // Load dentist in order to have data already loaded for History tab.
  useGetAllDentistsQuery();

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
    getOneOrder(orderNumber);
  }, []);

  useEffect(() => {
    if (orderData) {
      setOrder(orderData);
      const orderValidated = isValidated(orderData?.currentStep);
      setIsOrderValidated(orderValidated);
      loadOrderFilesData(
        dispatch,
        getOneDownloadableFile,
        downloadFromStorage,
        orderNumber,
        orderData.patient?.diagnostic?.patientFiles
      );
    }
  }, [orderData]);

  const orderNumberSplit = splitOrderNumber(orderNumber);

  return (
    <div>
      <BreadcrumbsBar>
        <BreadcrumbItem text={t('link.dashboard', { ns: 'common' })} link="/dashboard" />
        <BreadcrumbItem link="#" text={t('title')} />
      </BreadcrumbsBar>
      <div className={styles['order-detail']}>
        <div className={styles['order-detail__content']}>
          <header className={styles['order-detail__content__header']}>
            <div className={styles['order-detail__content__header__title']}>
              <Text
                type="title"
                label={orderNumberSplit.beforeLastPart}
                size="s"
                data-cy="order_detail_title"
              />
              <Text
                type="title"
                label={orderNumberSplit.lastPart}
                bold
                size="s"
                data-cy="order_detail_title"
              />
            </div>
            <div className={styles['order-detail__content__header__actions']} data-cy="actions">
              {!isOrderLoading && isOrderValidated && (
                <Tooltip>
                  <TooltipContent>{t(`tabs.detail.tooltips.cannotEdit`)}</TooltipContent>
                  <Button
                    label={t('action.edit', { ns: 'common' })}
                    iconLeft="fa-pen-to-square"
                    onClick={() => {
                      navigate(`/order/${orderNumber}/edit`);
                    }}
                    category="primary"
                    size="s"
                    isDisabled={true}
                    data-cy="button-edit"
                  />
                </Tooltip>
              )}
              {!isOrderLoading && !isOrderValidated && (
                <Button
                  label={t('action.edit', { ns: 'common' })}
                  iconLeft="fa-pen-to-square"
                  onClick={() => {
                    navigate(`/order/${orderNumber}/edit`);
                  }}
                  category="primary"
                  size="s"
                  data-cy="button-edit"
                />
              )}
              {!isOrderLoading && isOrderSubmitted && isOrderValidated && (
                <Button
                  label={t('actions.print')}
                  iconLeft="fa-print"
                  className={styles['order-detail__content__header__actions--not-first']}
                  onClick={() => {
                    document.getElementById('printTrigger').click();
                  }}
                  category="primary"
                  size="s"
                  data-cy="button-print"
                />
              )}
              {!isOrderLoading && isOrderSubmitted && !isOrderValidated && (
                <>
                  <Button
                    label={t('actions.reject')}
                    variant={ColorPropsEnum.DANGER}
                    iconLeft="fa-ban"
                    className={styles['order-detail__content__header__actions--not-first']}
                    onClick={() => {
                      setIsSideBarOpened(true);
                      setFormToDisplay(FormToDisplayEnum.REJECT_ORDER);
                    }}
                    category="secondary"
                    size="s"
                    data-cy="button-reject"
                  />
                  <Button
                    label={t('actions.accept')}
                    variant={ColorPropsEnum.SUCCESS}
                    iconLeft="fa-check"
                    className={styles['order-detail__content__header__actions--not-first']}
                    onClick={() => {
                      setIsSideBarOpened(true);
                      setFormToDisplay(FormToDisplayEnum.ACCEPT_ORDER);
                    }}
                    category="primary"
                    size="s"
                    data-cy="button-accept"
                  />
                </>
              )}
            </div>
          </header>
          <Tabs
            activeTabId={0}
            id="order-detail-tabs"
            className={styles['order-detail__content__tab']}
            operatingMode="index">
            <TabItem
              label={t('tabs.detail.title')}
              className={styles['order-detail__content__tab__content']}
              data-cy="detail-tab">
              <DetailTab order={order} skipManufacturingOrderLoad={skipManufacturingOrderLoad} />
            </TabItem>
            <TabItem
              label={t('tabs.comments.title')}
              className={styles['order-detail__content__tab__content']}
              counter={order?.comments?.length}>
              <Comments orderNumber={orderNumber} inSidebar={false} />
            </TabItem>
            <TabItem
              label={t('tabs.history.title')}
              className={styles['order-detail__content__tab__content']}>
              <HistoryTab orderNumber={orderNumber} />
            </TabItem>
          </Tabs>
        </div>
      </div>
      {formToDisplay === FormToDisplayEnum.REJECT_ORDER && (
        <SideBarModal
          title={t('rejectOrderForm.title', {
            orderNumber: order?.orderNumber
          })}
          isOpened={isSideBarOpened}
          closeOnOutsideClick={true}
          onClose={() => {
            setIsSideBarOpened(false);
            setFormToDisplay(FormToDisplayEnum.NONE);
          }}
          data-cy="reject-order-sidebar">
          <RejectOrderForm
            onRejectOrderFormSubmitCallback={(rejectCauses, rejectCauseComment: string) => {
              setIsSideBarOpened(false);
              setFormToDisplay(FormToDisplayEnum.NONE);
              // todo remove this after event management is implemented https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
              // this is temporary, once pubsub is set up, there will be no need for this
              setOrder({
                ...orderDetail,
                rejectCauseComment,
                rejectCauses,
                currentStep: WorkFlowStepPreModelingEnum.SUBMITTING
              });
            }}
            orderNumber={order?.orderNumber}
          />
        </SideBarModal>
      )}
      {formToDisplay === FormToDisplayEnum.ACCEPT_ORDER && (
        <SideBarModal
          title={t('validateOrderForm.title', {
            orderNumber: order?.orderNumber
          })}
          isOpened={isSideBarOpened}
          closeOnOutsideClick={true}
          onClose={() => {
            setIsSideBarOpened(false);
            setFormToDisplay(FormToDisplayEnum.NONE);
          }}
          data-cy="accept-order-sidebar">
          <ValidateOrderForm
            onValidateOrderSubmit={(nextWorkflowStep: WorkflowStepEnum) => {
              setIsSideBarOpened(false);
              setFormToDisplay(FormToDisplayEnum.NONE);
              // todo remove this after event management is implemented https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
              // this is temporary, once pubsub is set up, there will be no need for this
              setOrder({
                ...orderDetail,
                currentStep: nextWorkflowStep
              });
              // todo remove this after event management is implemented https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
              // once again, it's temporary but we won't retrieve manufacturing order for now
              // since it probably doesn't exist yet, we don't want to display an error
              setIsOrderValidated(isValidated(nextWorkflowStep));
              setSkipManufacturingOrderLoad(true);
            }}
            orderNumber={order?.orderNumber}
            isMultiFamilyOrder={order?.families?.length > 1}
          />
        </SideBarModal>
      )}
      <div className={styles['order-detail__print']}>
        <ReactToPrint
          trigger={() => {
            return <button id="printTrigger"></button>;
          }}
          content={() => printComponentRef}
        />
        {!isOrderLoading && order && (
          <PrintOrder
            ref={setPrintComponentRef}
            order={order}
            sortedItems={getSortedItemsToPrint(order.items)}
            existingTeethmap={<TeethMapDisplay order={order} displayProductBubbles={false} />}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetailPage;
