import React, { useCallback, useEffect, useState } from 'react';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { controlDatagridStateSelector } from '../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { datagridSettingsActions } from '../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services';
import {
  useLazyGetManufacturingOrdersQuery,
  useSetManufacturingOrderControlledMutation
} from '../../../../../services/manufacturing-orders-api.services';
import { colControlAction, colControlMenu } from './control';
import { FilterReducer, LoadDataProps } from '../../../../../models/datagrid';
import styles from '../../production-page.module.scss';
import { Button, SideBarModal } from '@anatoscope/circlestorybook';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import {
  ActionBtn,
  buildManufacturingOrdersFilters,
  buildSort,
  colFamilies,
  colInstructions,
  colDate,
  colManufacturingOrderProducts,
  colOrderNumber,
  colStatus,
  rowClassname
} from '../../../../../features/datagrid/columns';
import { WorkflowStepEnum } from '../../../../../enum/workflow-step';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { ToastType } from '../../../../../enum/feedback';
import Comments from '../../../../../features/comments/Comments';
import DatagridReload from '../../../../../features/datagrid/DatagridReload';

const ControlPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const datagridSettings = useAppSelector(controlDatagridStateSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [getManufacturingOrders] = useLazyGetManufacturingOrdersQuery();
  const [setManufacturingOrderControlled] = useSetManufacturingOrderControlledMutation();
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();
  const [selectedOrder, setSelectedOrder] = useState<ManufacturingOrder>(null);
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildManufacturingOrdersFilters(filterValue, WorkflowStepEnum.CONTROL);
    const sort = buildSort(sortInfo);
    return getManufacturingOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };
  const dataSource = useCallback(loadData, [commonTypes, isReloadNeeded]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: FilterReducer[]) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({ datagrid: 'control', filters: newFilterValue })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSingleSortInfo) => {
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'control', sort: newSortInfo }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'control' }));
  };

  const handleControlled = (manufacturingOrder: ManufacturingOrder) => {
    setManufacturingOrderControlled({ orderNumber: manufacturingOrder.orderNumber })
      .unwrap()
      .then(() => {
        dispatch(
          feedbackActions.setToast({
            message: t('toast.controlledManufacturingOrder', { ns: 'production' }),
            type: ToastType.SUCCESS
          })
        );
        setIsReloadNeeded(true);
      })
      .catch(() => {
        dispatch(
          feedbackActions.setToast({
            message: t('error.generic', { ns: 'common' }),
            type: ToastType.DANGER
          })
        );
      });
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayCommentsForm(false);
  };

  const handleClickComment = (order: ManufacturingOrder) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  const commentActionBtn: ActionBtn = {
    label: t('comments.comment', { ns: 'comment' }),
    onClick: (data: ManufacturingOrder) => {
      handleClickComment(data);
    }
  };

  return (
    <>
      <div className={styles['production-page__filters']}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
      </div>
      {!areCommonTypesLoading && (
        <DatagridFeature
          key="control"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={dataSource}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          columns={[
            colStatus,
            colOrderNumber(commentActionBtn),
            colControlAction(handleControlled),
            colFamilies(commonTypes),
            colManufacturingOrderProducts(),
            colInstructions,
            colDate('limitShippingDate'),
            colControlMenu(handleClickComment)
          ]}
          rowClassName={rowClassname}
        />
      )}
      {displayCommentsForm && (
        <SideBarModal
          title={t('comments.title', {
            orderNumber: selectedOrder.orderNumber,
            ns: 'comment'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <Comments
            orderNumber={selectedOrder.orderNumber}
            inSidebar={true}
            onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
          />
        </SideBarModal>
      )}
    </>
  );
};

export default ControlPage;
