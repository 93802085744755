import React, { useState } from 'react';
import { Box, BubblePicto, Button, TeethMap, Text } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from '../order-form.module.scss';
import useForm from '../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useGetConnectedUserQuery } from '../../../services/users-api.services';
import { DentalNotation } from '../../../enum/user';
import { ColorPropsEnum } from '../../../enum/color.enum';
import {
  zoneLinkPropsSelector,
  mapSvgLayerSelector,
  cursorsSelector,
  lineAndNumberColorsSelector,
  teethShadesSelector
} from '../../../store/map/map.selectors';
import { mapActions } from '../../../store/map/map.reducer';
import { PositionKeyString, ToolEnum } from '../../../enum/map.enum';
import { TeethMode } from '../../../enum/product.enum';
import { NotAllowedCursors } from '../../order-manager/teeth-map/cursors.utils';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const MouthForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const [activeTool, setActiveTool] = useState<ToolEnum>();

  const { data: connectedUser } = useGetConnectedUserQuery();

  const mapSvgLayers = useAppSelector(mapSvgLayerSelector);
  const zoneLinkProps = useAppSelector(zoneLinkPropsSelector);
  const cursors = useAppSelector(cursorsSelector);
  const lineAndNumberColors = useAppSelector(lineAndNumberColorsSelector);
  const teethShades = useAppSelector(teethShadesSelector);

  const onToolClick = (activeTool: ToolEnum) => {
    setActiveTool(activeTool);
    dispatch(mapActions.computeMouthSelectionTooth(activeTool));
    dispatch(mapActions.setTeethMode(TeethMode.SINGLE_TOOTH));
  };

  const submit = () => {
    nextCallback();
    dispatch(mapActions.resetMapContext());
    dispatch(mapActions.resetSelectionTeeth());
  };

  /**
   * Function to select a tooth.
   *
   * @param {PositionKeyString} selectedTooth - The tooth to be selected.
   */
  const handleSelectTooth = (selectedTooth: PositionKeyString): void => {
    if (activeTool === ToolEnum.EXTRACT) {
      dispatch(mapActions.toggleToothExtract(selectedTooth));
    }
    if (activeTool === ToolEnum.MISSING) {
      dispatch(mapActions.toggleToothMissing(selectedTooth));
    }
  };

  const { handleSubmit } = useForm({}, submit);

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['order-form__box']}>
      <form onSubmit={handleSubmit} className={styles['order-form__box__form']}>
        <div className={styles['order-form__box__form__content']}>
          <div className={styles['order-form__box__form__content__mouth']}>
            <div className={styles['order-form__box__form__content__mouth__content']}>
              <Box className={styles['order-form__box__intro']}>
                <Text label={t('createOrder.mouth.intro')} />
              </Box>
              <TeethMap
                hiddenBubbles={true}
                className={styles['order-form__box__form__content__mouth__content__teeth-map']}
                onClick={(tooth: PositionKeyString) => handleSelectTooth(tooth)}
                patientMouth={{ ...mapSvgLayers }}
                cursors={activeTool ? cursors : { ...NotAllowedCursors }}
                notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
                teethShades={teethShades}
                zoneLinkProps={zoneLinkProps}
                lineAndNumberColors={lineAndNumberColors}
              />
              <div
                className={styles['order-form__box__form__content__mouth__content__map-actions']}>
                <div
                  className={
                    styles['order-form__box__form__content__mouth__content__map-actions__button']
                  }>
                  <BubblePicto
                    color={ColorPropsEnum.FAMILY_REMOV}
                    displaysBorder={activeTool === ToolEnum.EXTRACT}
                    url="https://storage.googleapis.com/public-circle-dental/images/svg/extract-tooth.svg"
                    size="large"
                    data-cy="extractTeethButton"
                    isClickable={true}
                    onClick={() => onToolClick(ToolEnum.EXTRACT)}
                    mainTitle={t('createOrder.mouth.extractTeeth')}
                    mainTitleSize="xs"
                    className={
                      styles['order-form__box__form__content__mouth__content__map-actions__button']
                    }
                  />
                </div>
                <div
                  className={
                    styles['order-form__box__form__content__mouth__content__map-actions__button']
                  }>
                  <BubblePicto
                    color={ColorPropsEnum.FAMILY_REMOV}
                    displaysBorder={activeTool === ToolEnum.MISSING}
                    url="https://storage.googleapis.com/public-circle-dental/images/svg/missing-tooth.svg"
                    size="large"
                    data-cy="missingTeethButton"
                    isClickable={true}
                    onClick={() => onToolClick(ToolEnum.MISSING)}
                    mainTitle={t('createOrder.mouth.missingTeeth')}
                    mainTitleSize="xs"
                    className={
                      styles['order-form__box__form__content__mouth__content__map-actions__button']
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__submit-button form__submit-button--double">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="tertiary"
            onClick={previousCallback}
            iconLeft="fa-chevron-left"
            data-cy="previousButton"
          />
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="secondary"
            iconRight="fa-chevron-right"
            data-cy="nextButton"
          />
        </div>
      </form>
    </Box>
  );
};
export default MouthForm;
