import React from 'react';
import {
  ComponentType,
  OrderedLowerPositions,
  OrderedUpperPositions,
  PositionKey
} from '../../enum/component';
import { Box, BubblePicto, Chips, Text } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import { getLocalizedProperty } from '../../utils/utils';
import styles from './order-item-card.module.scss';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/product';
import i18next from '../../i18n';
import { isCategoryProvisional } from '../order-form/utils';
import { getComponentInItemByType, getItemDentalArch } from '../order-manager/teeth-map/utils';
import { CardDisplayMode, Family, ProductCategory } from '../../enum/product.enum';
import { OrderItem, OrderItemComponent } from '../../models/order';
import { getMaterialColor } from '../../utils/order.utils';

type OrderItemCardProps = {
  item: OrderItem;
  displayMode?: CardDisplayMode;
};

export const OrderItemCard = ({
  item,
  displayMode = CardDisplayMode.DEFAULT
}: OrderItemCardProps) => {
  const { t } = useTranslation(['orderDetail']);
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);

  const isPrintDisplayMode = displayMode === CardDisplayMode.PRINT;

  const sortTeethPosition = (teethPosition: PositionKey[]): PositionKey[] => {
    const sortedTeethPosition = [...teethPosition];
    const orderedPositions = [...OrderedUpperPositions, ...OrderedLowerPositions];
    return sortedTeethPosition.sort(
      (a, b) => orderedPositions.indexOf(a) - orderedPositions.indexOf(b)
    );
  };

  // Display provisional or definitive category (no need to display unit, bridge etc category)
  const finalCategory = (category: ProductCategory): string => {
    return isCategoryProvisional(category)
      ? t(`categories.${ProductCategory.PROVISIONAL}`, { ns: 'catalog' })
      : t(`categories.${ProductCategory.DEFINITIVE}`, { ns: 'catalog' });
  };

  const displayMaterialChips = (component: OrderItemComponent): React.JSX.Element => {
    return (
      <Chips
        firstLabel={t(`material.${component?.material?.code}`, { ns: 'component' })}
        secondLabel={getOrderItemStratificationLabel(component)}
        color={getMaterialColor(component.material.code)}
      />
    );
  };

  const getOrderItemStratificationLabel = (component: OrderItemComponent): string => {
    return component?.material?.stratification
      ? t(`material.stratification.${component?.material?.stratification}`, {
          ns: 'component'
        })
      : null;
  };

  const displayShadeChips = (component: OrderItemComponent): React.JSX.Element => {
    return (
      <Chips
        color={ColorPropsEnum[component.shade.code as keyof typeof ColorPropsEnum]}
        firstLabel={i18next.t(`shade.${component.shade.code.toUpperCase()}`, {
          ns: 'component'
        })}
      />
    );
  };

  const displayTeethPositionText = (component: OrderItemComponent): React.JSX.Element => {
    return <Text label={sortTeethPosition(component.teethPositions).join(', ')} />;
  };

  const displayComponent = (component: OrderItemComponent): React.JSX.Element => {
    const isToothOrPartialTooth = [ComponentType.TOOTH, ComponentType.PARTIAL_TOOTH].includes(
      component.componentType
    );
    return (
      <>
        {isToothOrPartialTooth && (
          <div className={styles[`order-item-card__content__line`]}>
            <Text
              className={styles[`order-item-card__content__line__title`]}
              label={t('orderItemCard.positions')}
              bold={true}
            />
            <div className={styles[`order-item-card__content__line__data`]}>
              {component?.teethPositions && displayTeethPositionText(component)}
            </div>
          </div>
        )}
        <div className={styles[`order-item-card__content__line`]} key={component.id}>
          <Text
            className={styles[`order-item-card__content__line__title`]}
            label={
              isToothOrPartialTooth && component?.teethPositions?.length > 0
                ? t('orderItemCard.tooth', {
                    count: component.teethPositions.length
                  })
                : t(`orderItemCard.${component.componentType.toLowerCase()}`)
            }
            bold={true}
          />

          <div className={styles[`order-item-card__content__line__data`]}>
            {component?.material && displayMaterialChips(component)}
            {component?.shade && displayShadeChips(component)}
            {component?.aspect && (
              <Text
                label={t(`aspect.${component?.aspect.code}`, {
                  ns: 'component'
                })}
              />
            )}
            {component?.shape && (
              <Text
                label={i18next.t(`shape.${component.shape.code.toUpperCase()}`, {
                  ns: 'component'
                })}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const priorityComponentSort: string[] = [
    ComponentType.TOOTH,
    ComponentType.PARTIAL_TOOTH,
    ComponentType.GINGIVA,
    ComponentType.FRAME
  ];

  const sortComponents = (a: OrderItemComponent, b: OrderItemComponent): number => {
    const indexA = priorityComponentSort.indexOf(a.componentType);
    const indexB = priorityComponentSort.indexOf(b.componentType);

    if (indexA === -1 && indexB === -1) {
      // if A and B are not in the priority list, alphabetical sort
      return a.componentType.localeCompare(b.componentType);
    } else if (indexA === -1) {
      // If A is not in priority list, B first
      return 1;
    } else if (indexB === -1) {
      // If B is not in priority list, A first
      return -1;
    } else {
      return indexA - indexB;
    }
  };

  return (
    <Box
      color={isPrintDisplayMode ? 'white' : 'grey'}
      padding={isPrintDisplayMode ? 'spacing-1' : 'spacing-5'}
      key={item.id}
      className={[styles[`order-item-card--${displayMode}`], styles['order-item-card']].join(' ')}
      data-cy="order-detail-item">
      <div className={styles[`order-item-card__header`]}>
        <div className={styles[`order-item-card__header__product`]}>
          <BubblePicto
            size="small"
            color={
              ColorPropsEnum[
                `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
            backgroundColor={isPrintDisplayMode ? ColorPropsEnum.WHITE : ColorPropsEnum.PRIMARY}
            url={item.product.imageUrl}
            isDashedBorder={isCategoryProvisional(item.product.category)}
            className={styles[`order-item-card__header__product__img`]}
            data-cy="order-detail-item-bubble"
          />
          <div className={styles[`order-item-card__header__product__label`]}>
            <Text
              label={item.product[getLocalizedProperty('label') as keyof Product] as string}
              bold={true}
              data-cy="order-detail-item-product"
            />
            <div className={styles[`order-item-card__header__product__attributes`]}>
              {toothComponent?.toothStratificationTechnique && (
                <Text
                  label={t(
                    `toothStratificationTechnique.${toothComponent?.toothStratificationTechnique}`,
                    {
                      ns: 'component'
                    }
                  )}
                />
              )}
              {item.product.family === Family.REMOV && (
                <Text
                  label={t(`arch.${getItemDentalArch(item)}`, {
                    ns: 'component'
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles[`order-item-card__header__category`]}>
          {item.product.category && (
            <Chips
              firstLabel={finalCategory(item.product.category)}
              color={ColorPropsEnum.BLACK}
              size={'m'}
            />
          )}
        </div>
      </div>
      {Object.assign([], item?.itemComponents)
        .filter((component: OrderItemComponent) => component.componentType !== ComponentType.MODELS)
        ?.sort(sortComponents)
        .map((component: OrderItemComponent) => {
          return <div key={component.id}>{displayComponent(component)}</div>;
        })}
    </Box>
  );
};
