import React, { useState } from 'react';
import {
  AttentionBox,
  Box,
  Button,
  Dropdown,
  Fieldset,
  Skeleton,
  Text,
  TextField
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './export-orders-form.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useForm from '../../../../utils/useForm';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { exportManufacturingOrders } from '../../../../services/manufacturing-orders-api.services';
import { HttpStatusCode } from 'axios';
import FileSaver from 'file-saver';
import moment from 'moment/moment';
import { getMessageError } from '../../../../utils/utils';
import { getLabUsersListForDropdownSelector } from '../../../../store/users/users.selectors';
import { useGetLabUsersQuery } from '../../../../services/users-api.services';
import { StringObject } from '../../../../models/common';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { ToastType } from '../../../../enum/feedback';
import { getWorkflowStepsForDropdown } from '../../../../features/order-form/prosthesis-form/add-product-modal/utils';

type props = {
  onSubmitCallback: (keepOpened: boolean) => void;
};
const ExportDesignedOrdersForm = ({ onSubmitCallback }: props) => {
  const { t } = useTranslation(['dashboard']);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const dispatch = useAppDispatch();
  const labUsersListForDropdown = useAppSelector(getLabUsersListForDropdownSelector());
  const { isLoading: isLoadingLabUsers } = useGetLabUsersQuery();

  const previousMonth = moment(new Date()).subtract(1, 'months');
  const firstDayOfPreviousMonth = previousMonth.startOf('month').format('YYYY-MM-DD');
  const lastDayOfPreviousMonth = previousMonth.endOf('month').format('YYYY-MM-DD');

  const onSubmit = () => {
    setIsExportLoading(true);
    dispatch(
      feedbackActions.setToast({
        message: t('exportOrdersModal.form.loading'),
        type: ToastType.INFO
      })
    );
    const startDate: Date = values.exportStartDate as Date;
    const endDate: Date = values.exportEndDate as Date;

    exportManufacturingOrders(
      startDate,
      endDate,
      values.assigneeEmail as string,
      values.step as string
    )
      .then((streamableFileObject) => {
        let keepOpened = false;
        if (streamableFileObject.status === HttpStatusCode.Ok) {
          const tempName = `export-orders-${moment(startDate).format('DD_MM_YYYY')}-${moment(
            endDate
          ).format('DD_MM_YYYY')}.xlsx`;

          FileSaver.saveAs(new Blob([streamableFileObject.data as BlobPart]), tempName);
        } else if (streamableFileObject.status === HttpStatusCode.NoContent) {
          dispatch(
            feedbackActions.setToast({
              message: t('exportOrdersModal.form.noContent'),
              type: ToastType.INFO
            })
          );
          keepOpened = true;
        }
        onSubmitCallback(keepOpened);
      })
      .catch((err) => {
        setError(getMessageError(err));
      })
      .finally(() => setIsExportLoading(false));
  };

  const customValidate = (submittedValues: { exportStartDate: string; exportEndDate: string }) => {
    const errors: StringObject = {};

    if (submittedValues.exportStartDate === '') {
      errors['exportStartDate'] = 'empty';
    }
    if (submittedValues.exportEndDate === '') {
      errors['exportEndDate'] = 'empty';
    }

    // This should never happen because we make sure to add min & max dates on inputs
    // But it's still nice to know it's there
    if (
      new Date(submittedValues.exportStartDate).getTime() >
      new Date(submittedValues.exportEndDate).getTime()
    ) {
      errors['exportEndDate'] = 'tooEarly';
    }

    return errors;
  };

  const { values, handleChange, handleBlur, handleSubmit, handleSelect, errors } = useForm(
    {
      exportStartDate: firstDayOfPreviousMonth,
      exportEndDate: lastDayOfPreviousMonth,
      assigneeEmail: '',
      step: ''
    },
    onSubmit,
    customValidate
  );

  return (
    <>
      {isLoadingLabUsers && (
        <>
          <div>
            <Skeleton type="text" className={styles['export-orders-form__skeleton']} width="100%" />
            <Skeleton type="text" className={styles['export-orders-form__skeleton']} width="100%" />
          </div>
          <div className={styles['export-orders-form__submit']}>
            <Skeleton type="title" className="skeleton" width="20%" />
          </div>
        </>
      )}

      {!isLoadingLabUsers && (
        <>
          {error && (
            <AttentionBox
              mode={ColorPropsEnum.DANGER}
              text={error}
              className={styles['export-orders-form__attention-box']}
              onClose={() => setError(null)}
            />
          )}
          <Box color={ColorPropsEnum.WHITE}>
            <>
              <Text
                label={t('exportOrdersModal.form.note')}
                className={styles['export-orders-form__warning']}
                size="s"
              />
              <Text
                label={t('exportOrdersModal.form.pickDates')}
                type="title"
                size="xs"
                bold={true}
              />
              <form onSubmit={handleSubmit} noValidate>
                <Fieldset
                  withBorder={false}
                  displayedInRow={true}
                  className={styles['export-orders-form__fieldset']}>
                  <TextField
                    id="exportStartDate"
                    name="exportStartDate"
                    value={values.exportStartDate}
                    max={values.exportEndDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    size="m"
                    type="date"
                    className={styles['export-orders-form__fieldset__input--date']}
                    helperText={
                      errors['exportStartDate']
                        ? t(
                            `exportOrdersModal.form.errors.exportStartDate.${errors['exportStartDate']}`
                          )
                        : ''
                    }
                    variant={
                      errors['exportStartDate'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
                    }
                    isRequired
                    data-cy="exportStartDate"
                  />
                  <TextField
                    id="exportEndDate"
                    name="exportEndDate"
                    value={values.exportEndDate}
                    min={values.exportStartDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    size="m"
                    type="date"
                    className={styles['export-orders-form__fieldset__input--date']}
                    helperText={
                      errors['exportEndDate']
                        ? t(
                            `exportOrdersModal.form.errors.exportEndDate.${errors['exportEndDate']}`
                          )
                        : ''
                    }
                    variant={
                      errors['exportEndDate'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
                    }
                    isRequired
                    data-cy="exportEndDate"
                  />
                </Fieldset>
                <Fieldset withBorder={false} className={styles['export-orders-form__fieldset']}>
                  <Dropdown
                    id="exportAssignee"
                    label={t('exportOrdersModal.form.assignee', { ns: 'dashboard' })}
                    data={labUsersListForDropdown}
                    value={values?.assigneeEmail}
                    size="m"
                    placeholder={t('assignOrderModal.placeholder', { ns: 'production' })}
                    onChange={(newValue: string) => handleSelect(newValue, 'assigneeEmail')}
                    isLoading={isLoadingLabUsers}
                  />
                </Fieldset>
                <Fieldset withBorder={false} className={styles['export-orders-form__fieldset']}>
                  <Dropdown
                    id="exportStep"
                    label={t('exportOrdersModal.form.step', { ns: 'dashboard' })}
                    data={getWorkflowStepsForDropdown()}
                    value={values?.step}
                    size="m"
                    placeholder={t('assignOrderModal.placeholder', { ns: 'production' })}
                    onChange={(newValue: string) => handleSelect(newValue, 'step')}
                  />
                </Fieldset>
                <div className={styles['export-orders-form__submit']}>
                  <Button
                    label={t('exportOrdersModal.form.submitLabel')}
                    isLoading={isExportLoading}
                    type="submit"
                  />
                </div>
              </form>
            </>
          </Box>
        </>
      )}
    </>
  );
};

export default ExportDesignedOrdersForm;
