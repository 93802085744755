import { ColorPropsEnum, MaterialColorEnum } from '../enum/color.enum';
import { ComponentType, MaterialEnum } from '../enum/component';
import {
  WorkflowManufacturingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum
} from '../enum/workflow-step';
import { ManufacturingOrder } from '../models/manufacturing-order';
import { OrderItem, OrderItemComponent } from '../models/order';

export const defaultProductIconUrl =
  'https://storage.googleapis.com/public-circle-dental/images/gear-solid.svg';

export const isSubmittingStep = (currentStep: string): boolean =>
  currentStep && currentStep === WorkflowStepEnum.SUBMITTING;

export const isSubmitted = (currentStep?: WorkflowStepEnum): boolean =>
  currentStep && currentStep !== WorkFlowStepPreModelingEnum.SUBMITTING;

export const isValidationStep = (currentStep: string): boolean =>
  currentStep && currentStep === WorkflowStepEnum.VALIDATION;

export const isValidated = (currentStep?: WorkflowStepEnum): boolean =>
  currentStep &&
  !Object.values(WorkFlowStepPreModelingEnum).includes(currentStep as WorkFlowStepPreModelingEnum);

export const isModelingStep = (currentStep: string): boolean =>
  currentStep &&
  (currentStep === WorkflowStepEnum.MODELING ||
    currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
    currentStep === WorkflowStepEnum.MODELING_PREPARE ||
    currentStep === WorkflowStepEnum.MODELING_REGISTER ||
    currentStep === WorkflowStepEnum.MODELING_DESIGN ||
    currentStep === WorkflowStepEnum.MODELING_EXPORT);

export const isManufactured = (currentStep: WorkflowStepEnum): boolean =>
  currentStep &&
  [WorkflowStepEnum.CONTROL, WorkflowStepEnum.DELIVERY, WorkflowStepEnum.DELIVERED].includes(
    currentStep as WorkflowPostModelingStepEnum
  );

export const isDeliveryStep = (currentStep: string): boolean =>
  currentStep && currentStep === WorkflowStepEnum.DELIVERY;

export const hasDeliveryStarted = (currentStep: WorkflowStepEnum): boolean => {
  return [WorkflowStepEnum.DELIVERY, WorkflowStepEnum.SHIPPED, WorkflowStepEnum.DELIVERED].includes(
    currentStep as unknown as WorkflowPostModelingStepEnum
  );
};

export const isAutomaticModelisationStep = (currentStep: WorkflowStepEnum): boolean =>
  currentStep &&
  (currentStep === WorkflowStepEnum.MODELING ||
    currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
    currentStep === WorkflowStepEnum.MODELING_REGISTER ||
    currentStep === WorkflowStepEnum.MODELING_EXPORT);

export const canChangeOrderStep = (currentStep: string): boolean =>
  currentStep &&
  !isSubmittingStep(currentStep) &&
  !isValidationStep(currentStep) &&
  !isDeliveryStep(currentStep);

/**
 * Find the most advanced manufacturing step that has been reached by ALL components of all products of the manufacturing order
 * Examples:
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 *   - 1 component is DESIGNED, all others are MANUFACTURED -> return DESIGNED
 *   - 1 component is DESIGNED, all others are TO_MANUFACTURE -> return DESIGNED
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 * @param manufacturingOrder
 */
export const getMostAdvancedCommonManufacturingStep = (
  manufacturingOrder: ManufacturingOrder
): WorkflowManufacturingStepEnum => {
  const allComponentsSteps = manufacturingOrder.products
    .map((product) => product.components.map((component) => component.manufacturingStep))
    .flat();
  let mostAdvancedStep: WorkflowManufacturingStepEnum = undefined;

  // We want the most advanced step that has been reach by ALL components
  // Iterate through WorkflowManufacturingStepEnum from the least advanced step to the most advanced
  // If at least one of the components is at this step : this is it
  Object.values(WorkflowManufacturingStepEnum).forEach((step) => {
    const componentsInThisStep = allComponentsSteps.filter(
      (componentStep) => componentStep === step
    );
    if (!mostAdvancedStep && componentsInThisStep.length > 0) {
      mostAdvancedStep = step;
    }
  });

  return mostAdvancedStep ?? WorkflowManufacturingStepEnum.TO_DESIGN;
};

export const getItemComponentAttribute = (
  item: OrderItem,
  componentType: ComponentType
): OrderItemComponent => {
  return item?.itemComponents?.find((itemComponent) => {
    return itemComponent.componentType === componentType;
  });
};

export const getMaterialColor = (material: MaterialEnum) => {
  return Object.keys(MaterialColorEnum).includes(material)
    ? ColorPropsEnum[material.replace('-', '_') as keyof typeof ColorPropsEnum]
    : ColorPropsEnum.WHITE;
};
