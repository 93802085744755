import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chips } from '@anatoscope/circlestorybook';
import styles from './print-product-list.module.scss';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ProductLine, SortedItemsForPrint } from './print-order';
import { Family, ProductCategory } from '../../enum/product.enum';

interface PrintProductListProps {
  sortedItems: SortedItemsForPrint;
}

const PrintProductList = ({ sortedItems }: PrintProductListProps) => {
  const { t } = useTranslation(['catalog']);

  return (
    <>
      {Object.keys(sortedItems).map((family) => {
        const categories = sortedItems[family as Family].map((categoryContent, categoryIndex) => {
          return (
            <React.Fragment key={`category_${family}_${categoryIndex}`}>
              <div key={family} className={styles['print-product-list__title--first']}>
                <Chips
                  firstLabel={t(`families.${family}`)}
                  color={
                    ColorPropsEnum[`FAMILY_${family.toUpperCase()}` as keyof typeof ColorPropsEnum]
                  }
                  size={'s'}
                  secondLabel={
                    categoryContent.displayCategoryChip
                      ? t(`categories.${categoryContent.category}`)
                      : undefined
                  }
                  dashed={categoryContent.category === ProductCategory.PROVISIONAL}
                />
              </div>
              <div key={`family_content_${family}_${categoryContent.category}`}>
                {categoryContent.items.map((item: ProductLine, itemIndex) => {
                  return (
                    <div
                      key={`item_${family}_${categoryContent.category}_${itemIndex}`}
                      className={styles['print-product-list__line']}>
                      {item.count > 1
                        ? item.label.replace('{{count}}', item.count.toString())
                        : item.label.replace('x{{count}} - ', '')}
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        });
        return <div key={`family_container_${family}`}>{categories}</div>;
      })}
    </>
  );
};

export default PrintProductList;
