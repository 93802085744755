import { ColorPropsEnum, FamilyColorEnum, SimpleColorsEnum } from '../enum/color.enum';
import {
  ComponentType,
  DentalArchEnum,
  GingivaShadeEnum,
  MaterialEnum,
  OrderedLowerPositions,
  OrderedUpperPositions,
  PositionKey,
  ToothShadeEnum,
  ToothShapeEnum
} from '../enum/component';
import {
  PositionKeyString,
  SelectionContextEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../enum/map.enum';
import { StumpMode, TeethMode } from '../enum/product.enum';
import { ComponentRule } from './component';
import { ProductRule } from './product';
import { ReactElement } from 'react';

export interface ProductAttributesForPosition {
  productId: number;
  uniqueProductId: string;
  teethMode: TeethMode;
}

export interface Position {
  teethShade: ToothShadeEnum;
  shape: ToothShapeEnum;
  missing: boolean;
  extract: boolean;
  stump: boolean;
  selection: ToothSelectionEnum;
  gingivaShade: GingivaShadeEnum;
  frame: MaterialEnum;
  range?: number;
  notation: PositionKeyString;
  arch: DentalArchEnum;
  zone_link: ZoneLinkEnum;
  svgLayers: string[];
  productIds: Array<ProductAttributesForPosition>; // can be removed if we find a better way to do things
  familyColor: FamilyColorEnum;
}

export interface MapClickPayload {
  notation: PositionKeyString;
  teethShade?: ToothShadeEnum;
  shape?: ToothShapeEnum;
  gingivaShade?: GingivaShadeEnum;
  frameMaterial?: MaterialEnum;
  familyColor?: FamilyColorEnum;
  componentTypes: ComponentType[];
  stumpMode?: StumpMode;
  svgLayers?: string[];
}

export interface MapContext {
  userAction: SelectionContextEnum;
  activeArch: DentalArchEnum;
  start: PositionKeyString;
  end: PositionKeyString;
  teethMode: TeethMode;
  teethComponentRule: ComponentRule;
  productRule: ProductRule;
  productId: number;
}

export interface MapReducer {
  positions: { [key: string]: Position };
  mapContext: MapContext;
}

export const notationToIndex: { [key: string]: number } = {
  '18': 0,
  '17': 1,
  '16': 2,
  '15': 3,
  '14': 4,
  '13': 5,
  '12': 6,
  '11': 7,
  '21': 8,
  '22': 9,
  '23': 10,
  '24': 11,
  '25': 12,
  '26': 13,
  '27': 14,
  '28': 15,
  '48': 16,
  '47': 17,
  '46': 18,
  '45': 19,
  '44': 20,
  '43': 21,
  '42': 22,
  '41': 23,
  '31': 24,
  '32': 25,
  '33': 26,
  '34': 27,
  '35': 28,
  '36': 29,
  '37': 30,
  '38': 31
};

export const sortedUpperPositionsArray: Array<PositionKeyString> = OrderedUpperPositions.map(
  (pos) => pos.toString() as PositionKeyString
);

export const sortedLowerPositionsArray: Array<PositionKeyString> = OrderedLowerPositions.map(
  (pos) => pos.toString() as PositionKeyString
);

export const sortedPositionsArray: Array<PositionKeyString> = [
  ...sortedUpperPositionsArray,
  ...sortedLowerPositionsArray
];

export type TeethmapBubbleFeatureProps = {
  backgroundColor: ColorPropsEnum;
  color: ColorPropsEnum;
  isClickable: boolean;
  size: 'small';
  tooltip?: {
    children: ReactElement;
    isClickable: boolean;
    position?: 'left' | 'right' | 'top' | 'bottom';
  };
  type: 'product';
  url: string;
  isDashedBorder: boolean;
};

export type TeethmapZoneLinkProps = {
  startPosition: PositionKey;
  endPosition: PositionKey;
  color: SimpleColorsEnum.GREY | SimpleColorsEnum.PINK;
};
