// To avoid duplicating an enum with all the steps and an enum with the modelling steps,
// we've created several enums, including the modelling enum, which we merge and export all together.
// The reason we're creating three enums instead of two is to maintain the order of the steps

export enum WorkFlowStepPreModelingEnum {
  SUBMITTING = 'submitting', // dentist is submitting the order
  VALIDATION = 'validation' // validate order by lab
}

export enum WorkflowModelingStepEnum {
  MODELING = 'modeling', // check products compatibility to CircleCAD
  MODELING_ANALYZE = 'modeling_analyze', // analyze patients files with CircleCAD (automatic)
  MODELING_PREPARE = 'modeling_prepare', // prepare patients files with CircleCAD (manual)
  MODELING_REGISTER = 'modeling_register', // register patients files with CircleCAD (automatic)
  MODELING_DESIGN = 'modeling_design', // design patients files with CircleCAD (manual)
  MODELING_EXPORT = 'modeling_export' // export manufacturing files with CircleCAD (automatic)
}

export enum WorkflowPostModelingStepEnum {
  MANUFACTURING = 'manufacturing', // manufacturing products and components
  ASSEMBLY = 'assembly', // Assemble products and components
  CONTROL = 'control', // control products and order
  DELIVERY = 'delivery', // order waiting to be shipped to dentist
  SHIPPED = 'shipped', // order shipped to dentist
  DELIVERED = 'delivered' // order has been delivered
}

export enum WorkflowManufacturingStepEnum {
  TO_DESIGN = 'TO_DESIGN', // Component is in the design phase
  DESIGNED = 'DESIGNED', // Component has been designed
  TO_MANUFACTURE = 'TO_MANUFACTURE', // Component is in the manufacturing phase
  MANUFACTURED = 'MANUFACTURED' // Component has been manufactured
}

export const WorkflowStepEnum = {
  ...WorkFlowStepPreModelingEnum,
  ...WorkflowModelingStepEnum,
  ...WorkflowPostModelingStepEnum
};
Object.freeze(WorkflowStepEnum);

export type WorkflowStepEnum =
  | WorkFlowStepPreModelingEnum
  | WorkflowModelingStepEnum
  | WorkflowPostModelingStepEnum;
