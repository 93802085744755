import React, { useCallback, useEffect, useState } from 'react';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { designDatagridStateSelector } from '../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services';
import {
  useAnalyzeManufacturingOrderMutation,
  useLazyGetManufacturingOrdersQuery,
  usePatchManufacturingOrderMutation,
  useSendModelingDesignRequestMutation
} from '../../../../../services/manufacturing-orders-api.services';
import {
  colActions,
  colAssignee,
  colCircleCadVersion,
  colDesignMenu,
  colFileActions
} from './design';
import { FilterReducer, LoadDataProps } from '../../../../../models/datagrid';
import { Button, Dialog, SideBarModal } from '@anatoscope/circlestorybook';
import styles from '../../production-page.module.scss';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import {
  ActionBtn,
  buildManufacturingOrdersFilters,
  buildSort,
  colDentistName,
  colDate,
  colManufacturingOrderProducts,
  colOrderNumber,
  colStatus,
  rowClassname
} from '../../../../../features/datagrid/columns';
import { datagridSettingsActions } from '../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { WorkflowStepEnum } from '../../../../../enum/workflow-step';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import UploadForm from './upload-form/UploadForm';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import {
  useDownloadOrderPatientFilesMutation,
  usePatchOrderMutation
} from '../../../../../services/orders-api.services';
import { getMessageError } from '../../../../../utils/utils';
import { downloadFiles } from '../../../../../features/file-manager/file.utils';
import AssignForm from '../../../../../features/assign-form/AssignForm';
import { ToastType } from '../../../../../enum/feedback';
import Comments from '../../../../../features/comments/Comments';
import { isLoadingFilesSelector } from '../../../../../store/orders/orders.selectors';
import DatagridReload from '../../../../../features/datagrid/DatagridReload';

const DesignPage = () => {
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();
  const datagridSettings = useAppSelector(designDatagridStateSelector);
  const isUploadingFiles = useAppSelector(isLoadingFilesSelector);

  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [getManufacturingOrders] = useLazyGetManufacturingOrdersQuery();
  const [downloadOrderPatientFiles, { isLoading: isDownloadingFiles }] =
    useDownloadOrderPatientFilesMutation();
  const [toggleManufactureOrder, { isSuccess: isSuccessToggleManufactureOrder }] =
    usePatchOrderMutation();
  const [
    toggleManufactureManufacturingOrder,
    { isSuccess: isSuccessToggleManufactureManufacturingOrder }
  ] = usePatchManufacturingOrderMutation();
  const [patchManufacturingOrder, { isSuccess: isSuccessPatchManufacturingOrder }] =
    usePatchManufacturingOrderMutation();
  const [patchOrder, { isSuccess: isSuccessPatchOrder, data: patchedOrder }] =
    usePatchOrderMutation();

  const [analyzeManufacturingOrder, { isSuccess: isSuccessAnalyzeManufacturingOrder }] =
    useAnalyzeManufacturingOrderMutation();
  const [sendModelingDesignRequest] = useSendModelingDesignRequestMutation();

  const [displayUploadForm, setDisplayUploadForm] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<ManufacturingOrder>(null);
  const [isDialogCloseUploadFilesOpened, setIsDialogCloseUploadFilesOpened] = useState(false);
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayAssignForm, setDisplayAssignForm] = useState<boolean>(false);
  const [isConfirmAnalyzeDialogOpened, setIsConfirmAnalyzeDialogOpened] = useState(false);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();

  useEffect(() => {
    if (isSuccessToggleManufactureOrder) {
      toggleManufactureManufacturingOrder({
        orderNumber: selectedOrder?.orderNumber,
        toManufacture: !selectedOrder.toManufacture
      });
    }
  }, [isSuccessToggleManufactureOrder]);

  useEffect(() => {
    if (isSuccessToggleManufactureManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t(
            selectedOrder.toManufacture
              ? 'toast.setNotToBeManufactured'
              : 'toast.setToBeManufactured',
            { ns: 'dashboard' }
          ),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSuccessToggleManufactureManufacturingOrder]);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildManufacturingOrdersFilters(filterValue, WorkflowStepEnum.MODELING);
    const sort = buildSort(sortInfo);
    return getManufacturingOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };
  const dataSource = useCallback(loadData, [
    commonTypes,
    isSuccessPatchOrder,
    isSuccessPatchManufacturingOrder,
    isSuccessToggleManufactureOrder,
    isSuccessToggleManufactureManufacturingOrder,
    isSuccessAnalyzeManufacturingOrder,
    isReloadNeeded
  ]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: FilterReducer[]) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({ datagrid: 'design', filters: newFilterValue })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSingleSortInfo) => {
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'design', sort: newSortInfo }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'design' }));
  };

  const onAnalyzeBtnClick = (manufacturingOrder: ManufacturingOrder) => {
    setSelectedOrder(manufacturingOrder);
    setIsConfirmAnalyzeDialogOpened(true);
  };

  const startCircleCadAnalyze = async () => {
    if (selectedOrder) {
      analyzeManufacturingOrder({ orderNumber: selectedOrder.orderNumber });
    }
  };

  const onCircleCadLaunchBtnClick = (manufacturingOrder: ManufacturingOrder) => {
    window.open(`streaming/${manufacturingOrder.orderNumber}`, '_blank');
    // Ask the backoffice to request modeling session in Circle CAD
    sendModelingDesignRequest(manufacturingOrder);
  };

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClickCloseSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayAssignForm(false);
    setDisplayUploadForm(false);
    setDisplayCommentsForm(false);
  };

  const handleToggleManufactureCallback = async (manufacturingOrder: ManufacturingOrder) => {
    setSelectedOrder(manufacturingOrder);
    toggleManufactureOrder({
      orderNumber: manufacturingOrder.orderNumber,
      toManufacture: !manufacturingOrder.toManufacture
    });
  };

  const handleClickAssign = (manufacturingOrder: ManufacturingOrder) => {
    setSelectedOrder(manufacturingOrder);
    setSideBarOpened(true);
    setDisplayAssignForm(true);
  };

  const handleClickUnassign = async (manufacturingOrder: ManufacturingOrder) => {
    setSelectedOrder(manufacturingOrder);
    patchManufacturingOrder({
      orderNumber: manufacturingOrder.orderNumber,
      assigneeEmail: null,
      assigneeName: null
    });
  };

  const handleClickComment = (order: ManufacturingOrder) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  const commentActionBtn: ActionBtn = {
    label: t('comments.comment', { ns: 'comment' }),
    onClick: (data: ManufacturingOrder) => {
      handleClickComment(data);
    }
  };

  useEffect(() => {
    if (isSuccessPatchManufacturingOrder) {
      patchOrder({
        orderNumber: selectedOrder?.orderNumber,
        assigneeEmail: null,
        assigneeName: null
      });
    }
  }, [isSuccessPatchManufacturingOrder]);

  useEffect(() => {
    if (isSuccessPatchOrder) {
      const message = patchedOrder?.assigneeEmail
        ? t('assignOrderModal.success')
        : t('toast.unassign', { ns: 'dashboard' });
      dispatch(
        feedbackActions.setToast({
          message: message,
          type: ToastType.SUCCESS
        })
      );
      setSelectedOrder(null);
    }
  }, [isSuccessPatchOrder]);

  useEffect(() => {
    if (isSuccessAnalyzeManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.orderConfirmAnalyze'),
          type: ToastType.SUCCESS
        })
      );
      setSelectedOrder(null);
      setIsConfirmAnalyzeDialogOpened(false);
    }
  }, [isSuccessAnalyzeManufacturingOrder]);

  const onSubmitAssignManufacturingOrder = () => {
    setIsReloadNeeded(true);
    setSideBarOpened(false);
    setDisplayAssignForm(false);
  };

  const handleClickDownload = (order: ManufacturingOrder): void => {
    document.body.style.cursor = 'wait';
    dispatch(
      feedbackActions.setToast({
        message: t('fileDownload.pending'),
        type: ToastType.INFO
      })
    );
    downloadOrderPatientFiles(order.orderNumber)
      .unwrap()
      .then((result) => {
        if (!result?.downloadUrl) {
          dispatch(
            feedbackActions.setToast({
              message: t('fileDownload.empty'),
              type: ToastType.INFO
            })
          );
        } else {
          downloadFiles(result.downloadUrl);
          dispatch(feedbackActions.resetToast());
        }
      })
      .finally(() => (document.body.style.cursor = 'default'))
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  };

  const handleClickUpload = (order: ManufacturingOrder) => {
    setSelectedOrder(order);
    setDisplayUploadForm(true);
  };

  const tryToCloseUploadSideBar = () => {
    if (isUploadingFiles) {
      setIsDialogCloseUploadFilesOpened(true);
    } else {
      closeUploadSideBar();
    }
  };

  const closeUploadSideBar = () => {
    setIsDialogCloseUploadFilesOpened(false);
    setDisplayUploadForm(false);
  };

  return (
    <>
      <Dialog
        title={t('analyzeDialog.title', {
          orderNumber: `${selectedOrder?.orderNumber}`
        })}
        text={t('analyzeDialog.text')}
        isOpened={isConfirmAnalyzeDialogOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('analyzeDialog.action')}
        onCancel={() => setIsConfirmAnalyzeDialogOpened(false)}
        onConfirm={startCircleCadAnalyze}
      />
      <Dialog
        title={t('fileUpload.dialog.closeUploadFiles.title')}
        text={t('fileUpload.dialog.closeUploadFiles.text')}
        isOpened={isDialogCloseUploadFilesOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('action.close', { ns: 'common' })}
        onCancel={() => setIsDialogCloseUploadFilesOpened(false)}
        onConfirm={closeUploadSideBar}
      />
      <div id="action-buttons" className={styles['production-page__filters']}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
      </div>
      {!areCommonTypesLoading && (
        <DatagridFeature
          key="design"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={dataSource}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          columns={[
            colStatus,
            colOrderNumber(commentActionBtn),
            colManufacturingOrderProducts(120),
            colAssignee(handleClickAssign),
            colCircleCadVersion,
            colActions(onAnalyzeBtnClick, onCircleCadLaunchBtnClick),
            colFileActions(handleClickUpload, handleClickDownload, isDownloadingFiles),
            colDentistName,
            colDate('limitShippingDate'),
            colDesignMenu(
              handleToggleManufactureCallback,
              handleClickAssign,
              handleClickUnassign,
              handleClickComment
            )
          ]}
          rowClassName={rowClassname}
        />
      )}

      {displayUploadForm && (
        <SideBarModal
          title={t('fileUpload.title', { orderNumber: selectedOrder?.orderNumber })}
          isOpened={displayUploadForm}
          onClose={tryToCloseUploadSideBar}>
          <UploadForm
            onSubmitCallback={tryToCloseUploadSideBar}
            orderNumber={selectedOrder?.orderNumber}
          />
        </SideBarModal>
      )}

      {displayAssignForm && (
        <SideBarModal
          title={t('assignOrderModal.title.order', { orderNumber: selectedOrder?.orderNumber })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <AssignForm
            onSubmitAssignManufacturingOrderCallback={onSubmitAssignManufacturingOrder}
            orderNumber={selectedOrder?.orderNumber}
          />
        </SideBarModal>
      )}
      {displayCommentsForm && (
        <SideBarModal
          title={t('comments.title', {
            orderNumber: selectedOrder?.orderNumber,
            ns: 'comment'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <Comments
            orderNumber={selectedOrder?.orderNumber}
            inSidebar={true}
            onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
          />
        </SideBarModal>
      )}
    </>
  );
};

export default DesignPage;
