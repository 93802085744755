import React from 'react';
import { Provider } from 'react-redux';
import store from './store/index';
import './styles/_index.scss';
import { createRoot } from 'react-dom/client';
import './services/globalInterceptorSetup';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

// Remove deprecated ReactDatagrid.
// Remove this part if our forked project contains a correction about DefaultProps deprecation.
if (WEBPACK_MODE !== 'production') {
  // eslint-disable-next-line no-console
  const originalWarn = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    const regex = /(Inovua(ScrollContainer|ComboBox|DataGridCell)|TheGrid)/;
    if (regex.test(args.toString())) {
      return;
    }
    originalWarn(...args);
  };
}
// endblock.

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
