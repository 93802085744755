// This is temporary
// To Do: add a date format parameter (short / medium / long )
// handle locale
// separator should not be in parameters
// Use moment library

import i18next from 'i18next';
import { ErrorCode } from '../enum/feedback';
import { EstablishmentType } from '../enum/establishment';
import i18n from '../i18n';
import {
  AdminRole,
  DentalNotation,
  DentistRole,
  DesignerRole,
  LabUserRole,
  Language
} from '../enum/user';
import { DentistInfos, LabUser } from '../models/user';

export const formatDate = (dateToFormat: Date, options?: Intl.DateTimeFormatOptions) => {
  options = options
    ? { ...options, hour12: false }
    : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return dateToFormat.toLocaleString(i18n.language, options);
};

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const isClinic = (establishmentType: string): boolean => {
  return establishmentType?.toLowerCase() === EstablishmentType.CLINIC.toLowerCase();
};

export const getLocalizedProperty = (property: string): string => {
  switch (property) {
    case 'name':
    case 'label':
      // This adds the locale after the filter name (for example, label -> labelFr or labelEn)
      return `label${capitalizeFirstLetter(i18next.language)}`;
    case 'productLabel':
      return `productLabel${capitalizeFirstLetter(i18next.language)}`;
    case 'products':
      return `products${capitalizeFirstLetter(i18next.language)}`;
    default:
      return property;
  }
};

export const getNameFromEmail = (users: (DentistInfos | LabUser)[], email: string) => {
  const user = users.find((user: DentistInfos | LabUser) => user.email === email);
  return `${user?.firstName} ${user?.lastName}`;
};

/**
 * Check if lab user role.
 * @param role role to check
 * @return true if lab user role
 */
export function isLabUser(role: AdminRole | DentistRole | DesignerRole | LabUserRole): boolean {
  return role in LabUserRole;
}

export function isSupervisor(role: AdminRole | DentistRole | DesignerRole | LabUserRole): boolean {
  return role === LabUserRole.SUPERVISOR;
}

export const dentalNotations = Object.keys(DentalNotation).map((value: DentalNotation) => {
  return {
    label: i18next.t(`createDentist.customization.${value.toLowerCase()}.label`, { ns: 'dentist' }),
    value: value,
    helperText: i18next.t(`createDentist.customization.${value.toLowerCase()}.helperText`, {
      ns: 'dentist'
    })
  };
});

export const languages = Object.keys(Language).map((value: Language) => {
  return { label: i18next.t(`language.${value}`, { ns: 'common' }), value: value };
});
