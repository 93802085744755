import React, { useEffect, useState } from 'react';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  Chips,
  Dialog,
  Divider,
  IconButton,
  SideBarModal,
  Skeleton,
  Text
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/hooks';
import {
  useDeactivateDentistMutation,
  useGetOneUserQuery,
  usePatchDentistMutation,
  useResetPasswordMutation
} from '../../services/users-api.services';
import { Country, Language } from '../../enum/user';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { Establishment } from '../../models/establishment';
import styles from './user-details.module.scss';
import { formatDate, isClinic, isLabUser } from '../../utils/utils';
import i18next from 'i18next';
import { getCustomizedTranslations, UserDetailsCustomizedTranslations } from './user-details';
import IdentificationForm from '../../pages/private/dentist-page/dentist-detail-page/dentist-forms/IdentificationForm';
import { DentistInfos } from '../../models/user';
import CustomizationForm from '../../pages/private/dentist-page/dentist-detail-page/dentist-forms/CustomizationForm';
import { usersActions } from '../../store/users/users.reducer';
import EstablishmentForm from '../../pages/private/dentist-page/dentist-detail-page/dentist-forms/EstablishmentForm';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ToastType } from '../../enum/feedback';

export enum UserDetailsRefererEnum {
  USER_DETAILS = 'USER_DETAILS',
  PROFILE_PAGE = 'PROFILE_PAGE'
}

type Props = {
  referer: UserDetailsRefererEnum;
  email: string;
};

const UserDetails = ({ email, referer }: Props) => {
  const { t } = useTranslation(['dentist']);
  const dispatch = useAppDispatch();
  const { data: user, isLoading, error } = useGetOneUserQuery(email);
  const [resetPassword, { isLoading: isLoadingResetPassword, isSuccess: isResetPassword }] =
    useResetPasswordMutation();
  const [
    deactivateDentist,
    { isLoading: isLoadingDeactivateDentist, isSuccess: isDeactivatedDentist }
  ] = useDeactivateDentistMutation();
  const [patchDentist, { isSuccess: isPatchedDentist }] = usePatchDentistMutation();

  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState<boolean>(false);
  const [isOpenedCustomization, setIsOpenedCustomization] = useState(false);
  const [isOpenedIdentification, setIsOpenedIdentification] = useState(false);
  const [isOpenedClinic, setIsOpenedClinic] = useState(false);

  const customizedTranslations: UserDetailsCustomizedTranslations = getCustomizedTranslations(
    user,
    referer
  );

  useEffect(() => {
    if (user) {
      dispatch(usersActions.setDentist(user as DentistInfos));
    }
  }, [user]);

  useEffect(() => {
    if (isDeactivatedDentist) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.dentistDeactivated'),
          type: ToastType.SUCCESS
        })
      );
      setIsDialogDeactivateOpened(false);
    }
  }, [isDeactivatedDentist]);

  useEffect(() => {
    if (isResetPassword) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.mailSent'),
          type: ToastType.SUCCESS
        })
      );
      setIsDialogResetPasswordOpened(false);
    }
  }, [isResetPassword]);

  useEffect(() => {
    if (isPatchedDentist) {
      dispatch(usersActions.resetDentist());
      dispatch(
        feedbackActions.setToast({
          message: t('toast.userUpdated'),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isPatchedDentist]);

  const deactivate = async () => {
    if (user) {
      deactivateDentist(user.email);
    }
  };

  const resetUserPassword = async () => {
    setIsDialogResetPasswordOpened(false);
    if (user) {
      resetPassword(user.email);
    }
  };

  const handleSubmit = (dentistParam: Partial<DentistInfos>, email: string) => {
    patchDentist({ ...dentistParam, email });
  };

  const displayEstablishment = (establishment: Establishment, title: string) => {
    return (
      <>
        <section className={styles['user-detail__content__section']}>
          <header className={styles['user-detail__content__section__header']}>
            <Text
              className={styles['user-detail__content__section__header__title']}
              size="s"
              type="title"
              label={title}></Text>
            {referer === UserDetailsRefererEnum.USER_DETAILS && isClinic(establishment.type) && (
              <IconButton
                color={ColorPropsEnum.PRIMARY}
                iconSize={'sm'}
                faIconClass="fa-pen-to-square"
                onClick={() => setIsOpenedClinic(true)}
                radius="full"
              />
            )}
          </header>
          {isLoading ? (
            <>
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" />
            </>
          ) : (
            !error && (
              <>
                <Text label={establishment.name}></Text>
                <Text label={establishment.address.address}></Text>
                {establishment.address.additionalAddress && (
                  <Text label={establishment.address.additionalAddress}></Text>
                )}

                <Text
                  label={
                    establishment.address.zipCode +
                    ' ' +
                    establishment.address.city +
                    ' ' +
                    (establishment.address.region ?? '')
                  }></Text>
                <Text
                  label={
                    Object.values(Country).includes(establishment.address.country as Country)
                      ? t(`countries.${establishment.address.country.toLowerCase()}`, {
                          ns: 'common'
                        })
                      : ''
                  }></Text>
              </>
            )
          )}
          {referer === UserDetailsRefererEnum.USER_DETAILS && isClinic(establishment.type) && (
            <SideBarModal
              title={title}
              isOpened={isOpenedClinic}
              onClose={() => setIsOpenedClinic(false)}>
              <EstablishmentForm
                saveCallback={handleSubmit}
                closeSideNavCallback={setIsOpenedClinic}
                establishment={establishment}
              />
            </SideBarModal>
          )}
        </section>
        <section className={styles['user-detail__content__divider']}>
          <Divider />
        </section>
      </>
    );
  };

  return (
    <>
      <Dialog
        title={t('dialog.deactivate.title', {
          ns: 'dentist',
          user: `${user?.firstName} ${user?.lastName}`
        })}
        text={t('dialog.deactivate.text')}
        isLoading={isLoadingDeactivateDentist}
        isOpened={isDialogDeactivateOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.deactivate.action')}
        onCancel={() => setIsDialogDeactivateOpened(false)}
        onConfirm={deactivate}
      />
      <Dialog
        title={customizedTranslations.resetPasswordDialogTitle}
        text={t('dialog.resetPassword.text', { email: user?.email })}
        isLoading={isLoadingResetPassword}
        isOpened={isDialogResetPasswordOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.resetPassword.action')}
        onCancel={() => setIsDialogResetPasswordOpened(false)}
        onConfirm={resetUserPassword}
      />
      {referer === UserDetailsRefererEnum.USER_DETAILS && (
        <BreadcrumbsBar className={styles['user-detail__breadcrumb']}>
          <BreadcrumbItem link="/dentist" text={t('tabs.dentists')} />
          <BreadcrumbItem link={`/dentist/detail/${user?.email}`} text={t('detailDentist.title')} />
        </BreadcrumbsBar>
      )}
      <div className={styles['user-detail__content']}>
        <section className={styles['user-detail__content__section']}>
          <header className={styles['user-detail__content__section__header--name']}>
            <div className={styles['user-detail__content__section__header__name-with-status']}>
              {user && (
                <>
                  <Text
                    className={styles['user-detail__content__section__header__title']}
                    label={`${user.firstName} ${user.lastName}`}
                    size="s"
                    type="title"></Text>
                  <div className={styles['user-detail__content__section__header__status']}>
                    {isLoading && <Skeleton type="text" />}
                    {!isLoading && user.deletedAt && (
                      <Chips
                        firstLabel={i18next.t('inactive', {
                          ns: 'common'
                        })}
                        secondLabel={formatDate(new Date(user.deletedAt))}
                        color={ColorPropsEnum.DANGER}
                      />
                    )}
                    {!isLoading && !user.deletedAt && (
                      <Chips
                        firstLabel={i18next.t('active', {
                          ns: 'common'
                        })}
                        color={ColorPropsEnum.SUCCESS}
                      />
                    )}
                  </div>
                  {referer === UserDetailsRefererEnum.USER_DETAILS && (
                    <IconButton
                      color={ColorPropsEnum.PRIMARY}
                      iconSize={'sm'}
                      faIconClass="fa-pen-to-square"
                      onClick={() => setIsOpenedIdentification(true)}
                      radius="full"
                    />
                  )}
                </>
              )}
            </div>
            <div className={styles['user-detail__content__section__actions']}>
              {!user?.deletedAt && (
                <>
                  {referer === UserDetailsRefererEnum.USER_DETAILS && (
                    <Button
                      className={styles['user-detail__content__section__actions__button']}
                      label={t('detailDentist.deactivate')}
                      onClick={() => setIsDialogDeactivateOpened(true)}
                      category="secondary"
                      size={'s'}
                    />
                  )}
                  <Button
                    className={styles['user-detail__content__section__actions__button']}
                    label={customizedTranslations.resetPasswordBtnLabel}
                    onClick={() => setIsDialogResetPasswordOpened(true)}
                    category="primary"
                    size={'s'}
                  />
                </>
              )}
            </div>
          </header>
          <div className={styles['user-detail__content__section__main']}>
            <div>
              <div className={styles['user-detail__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('createDentist.identification.email')}></Text>
                {isLoading && <Skeleton type="text" />}
                {!isLoading && !error && <Text label={user.email ? user.email : ''}></Text>}
              </div>
              {!isLoading && user?.phoneNumber && (
                <div className={styles['user-detail__content__section__main__item']}>
                  <Text
                    color={ColorPropsEnum.GREY}
                    label={t('createDentist.identification.phone')}></Text>
                  {!error && <Text label={user?.phoneNumber}></Text>}
                </div>
              )}
              <div className={styles['user-detail__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('createDentist.identification.role')}></Text>
                {isLoading && <Skeleton type="text" />}
                {!isLoading && !error && (
                  <Text
                    label={t(`user.roles.${user?.role.toLowerCase()}`, {
                      ns: 'common'
                    })}></Text>
                )}
              </div>
              {referer === UserDetailsRefererEnum.USER_DETAILS && (
                <div>
                  <Text
                    color={ColorPropsEnum.GREY}
                    label={t('detailDentist.userAllowedToOrder')}></Text>
                  {isLoading && <Skeleton type="text" />}
                  {!isLoading && !error && (
                    <Chips
                      firstLabel={t(user?.isAllowedToOrder ? 'yes' : 'no', {
                        ns: 'common'
                      })}
                      color={
                        user?.isAllowedToOrder ? ColorPropsEnum.SUCCESS : ColorPropsEnum.WARNING
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {referer === UserDetailsRefererEnum.USER_DETAILS && (
            <SideBarModal
              title={t('createDentist.wizard.identification')}
              isOpened={isOpenedIdentification}
              onClose={() => setIsOpenedIdentification(false)}>
              <IdentificationForm
                saveCallback={handleSubmit}
                closeSideNavCallback={setIsOpenedIdentification}
              />
            </SideBarModal>
          )}
        </section>
        <section className={styles['user-detail__content__divider']}>
          <Divider />
        </section>
        {!isLoading && user?.isAllowedToOrder && (
          <>
            <section className={styles['user-detail__content__section']}>
              <header className={styles['user-detail__content__section__header']}>
                <Text
                  className={styles['user-detail__content__section__header__title']}
                  label={t('detailDentist.statistics')}
                  size="s"
                  type="title"
                />
              </header>
              <div>
                <div>
                  <div>
                    <Text
                      color={ColorPropsEnum.GREY}
                      label={t('detailDentist.ordersNumber')}></Text>
                    <Text label="15"></Text>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles['user-detail__content__divider']}>
              <Divider />
            </section>
          </>
        )}
        {!isLoading &&
          user &&
          'clinic' in user &&
          Object.keys(user?.clinic).length > 0 &&
          displayEstablishment(user.clinic, t('detailDentist.clinic'))}
        {!isLoading &&
          user &&
          'laboratory' in user &&
          Object.keys(user?.laboratory).length > 0 &&
          displayEstablishment(
            user.laboratory,
            isLabUser(user.role)
              ? t('link.myLaboratory', { ns: 'common' })
              : t('link.partnerLaboratory', { ns: 'common' })
          )}
        {!isLoading &&
          user &&
          'designCenter' in user &&
          Object.keys(user?.designCenter).length > 0 &&
          displayEstablishment(user.designCenter, t('link.designCenter', { ns: 'common' }))}
        <section className={styles['user-detail__content__section']}>
          <header className={styles['user-detail__content__section__header']}>
            <Text
              className={styles['user-detail__content__section__header__title']}
              label={t('detailDentist.customization')}
              size="s"
              type="title"
            />
            {referer === UserDetailsRefererEnum.USER_DETAILS && (
              <IconButton
                color={ColorPropsEnum.PRIMARY}
                iconSize={'sm'}
                faIconClass="fa-pen-to-square"
                onClick={() => setIsOpenedCustomization(true)}
                radius="full"
              />
            )}
          </header>
          <div className={styles['user-detail__content__section__main__item']}>
            <Text
              color={ColorPropsEnum.GREY}
              label={t('createDentist.customization.language')}></Text>
            {isLoading && <Skeleton type="text" />}
            {!isLoading && !error && (
              <Text
                label={
                  Object.values(Language).includes(user.language)
                    ? t(`language.${user.language}`, { ns: 'common' })
                    : t('detailDentist.unknown')
                }></Text>
            )}
          </div>
          <div>
            <Text
              color={ColorPropsEnum.GREY}
              label={t('createDentist.customization.dentalNotation')}></Text>
            {isLoading && <Skeleton type="text" />}
            {!isLoading && !error && <Text label={customizedTranslations.dentalNotation}></Text>}
          </div>
          {referer === UserDetailsRefererEnum.USER_DETAILS && (
            <SideBarModal
              title={t('createDentist.wizard.customization')}
              isOpened={isOpenedCustomization}
              onClose={() => setIsOpenedCustomization(false)}>
              <CustomizationForm
                saveCallback={handleSubmit}
                closeSideNavCallback={setIsOpenedCustomization}
              />
            </SideBarModal>
          )}
        </section>
      </div>
    </>
  );
};

export default UserDetails;
