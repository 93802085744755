import React from 'react';
import styles from './quick-filter.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

type Props = {
  label: string;
  className?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

const QuickFilter = ({
  label,
  className = '',
  isDisabled = false,
  isSelected = false,
  onClick = undefined
}: Props) => {
  return (
    <button
      data-cy="quickfilter"
      onClick={onClick}
      disabled={isDisabled}
      className={[
        styles[`quick-filter__button`],
        isSelected ? styles[`quick-filter__button--selected`] : '',
        className
      ].join(' ')}>
      <FontAwesomeIcon
        icon={faFilter}
        className={[
          styles['quick-filter__button__icon'],
          isSelected ? styles[`quick-filter__button__icon--selected`] : ''
        ].join(' ')}
      />
      {label}
    </button>
  );
};

export default QuickFilter;
